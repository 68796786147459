import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";
import { isValidInput } from "../../utils/validations";
import { useLazyDeleteProductsQuery, useLazyGetProductsQuery, useLazyStatusChangeQuery } from "../../services/products";
import { showError, showToast } from "../../constants/toast";
import { CommonBody, ProductData } from "../../types/General";
import { ExportModal2, Pagination, WarnModal } from "../../components";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../constants/Loader";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Rating from '@mui/material/Rating';
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";


const ManageProducts = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1)
  const [open, setOpen] = useState(false);
  const [getAllProducts, { isLoading }] = useLazyGetProductsQuery()
  const [deleteById] = useLazyDeleteProductsQuery()
  const [product, setProduct] = useState<ProductData[]>([])
  const [statusChange] = useLazyStatusChangeQuery()
  const [selectedId, setSelectedId] = useState<string>("")
  const [totalCount, setTotalCount] = useState(0);
  let totalPages = Math.ceil(totalCount / 15);
  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [open1, setOpen1] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const handleDateChange = (event: any) => {
    setStartDate(event);
  };

  const handleDateChange1 = (event: any) => {
    setEndDate(event);
  };

  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];


  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await statusChange({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Product status updated successfully");
        await getProducts();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating category status");
    }
  };

  const getProducts = async () => {
    try {
      const res = await getAllProducts({
        page: page,
        size: 15,
        query: debouncedSearchTerm.trim(),
        fromDate: startDate ? moment(startDate).format(
          'YYYY-MM-DDTHH:mm:ss[Z]',
        ) : "",
        toDate: endDate ? moment(endDate).format(
          'YYYY-MM-DDTHH:mm:ss[Z]',
        ) : "",
      }).unwrap();
      if (res?.statusCode === 200) {
        setProduct(res?.data?.product || [])
        setTotalCount(res?.data?.count)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    getProducts()
  }, [searchTerm, debouncedSearchTerm, page, startDate, endDate])

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Product Specifications"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };


  useEffect(() => {
    checkPermission();
  }, [UserData])
  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Product Specifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            {hidePermission?.isEdit || UserData?.role === 1 ? (
              <div>
                <Button
                  sx={{ margin: "5px" }}
                  className="btn btn_primary"
                  onClick={() => setOpen1(true)}
                >
                  <FileDownloadIcon /> Export
                </Button>
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-products/add")}
                >
                  Add Product
                </Button>
              </div>
            ) : null}
          </Box>
          <Box className="crds_date" >
            <div>
              <Typography>Start Date</Typography>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={startDate}
                  className="adapter_picker"
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            </div>
            <div>
              <Typography>End Date</Typography>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={endDate}
                  className="adapter_picker"
                  onChange={handleDateChange1}

                />
              </LocalizationProvider>
            </div>
            {startDate || endDate ? (
              <Button
                onClick={() => { setStartDate(null); setEndDate(null) }}
                style={{ marginLeft: "1%", borderRadius: "25px", background: "gray", marginTop: "2%" }}
                className="btn btn_primary">Clear</Button>
            ) : ("")}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Product Image</TableCell>
                  <TableCell align="center">Product Name/Code</TableCell>
                  <TableCell align="center">Category</TableCell>
                  <TableCell align="center">Sub-category</TableCell>
                  <TableCell align="center">Variant quantity</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Rating</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              {product?.length ? (
                product.map((row, i) => (
                  <TableBody>
                    <TableRow key={row?._id}>
                      <TableCell align="center">  {(page - 1) * 15 + i + 1}</TableCell>
                      <TableCell align="center">
                        <figure className="user_img">
                          <img src={row?.images[0]?.type === "image" ? (row.images[0].src) : (row?.images[0]?.thumbnailUrl)} alt="" />
                        </figure>
                      </TableCell>
                      <TableCell align="center">{row?.name}</TableCell>
                      <TableCell align="center">{row?.category?.name}</TableCell>
                      <TableCell align="center">{row?.subcategory?.name}</TableCell>
                      <TableCell align="center">{row?.noOfProductVariants}</TableCell>
                      <TableCell align="center">
                        <Switch {...label} checked={!row?.isBlocked} size="small" onChange={() => {

                          updateUserStatus(row?._id, !row?.isBlocked)
                        }
                        } />
                      </TableCell>
                      <TableCell align="center"><Rating name="read-only" value={row?.averageRating} readOnly /> </TableCell>

                      <TableCell align="center">
                        <Box className="table_actions">
                          <Tooltip title="View">
                            <IconButton
                              onClick={() => navigate("/manage-products/details/" + row?._id)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          {hidePermission?.isEdit || UserData?.role === 1 ? (
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => navigate("/manage-products/edit/" + row?._id)}
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {hidePermission?.isDelete || UserData?.role === 1 ? (
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setSelectedId(row?._id)
                                  setOpen(true)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >

                    No Products Found
                  </TableCell>
                </TableBody>
              )}
              {/* </TableBody> */}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        module={product}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        name="Product"
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(deleteById, selectedId, getProducts)}
      />
      <ExportModal2
        open={open1}
        setOpen={setOpen1}
      />
    </div>
  );
};

export default ManageProducts;