import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLazyGetRatingByIdQuery } from "../../services/products";
import { ratingType } from "../../types/General";
import { showError } from "../../constants/toast";
import Rating from '@mui/material/Rating';
import Loader from "../../constants/Loader";

const ReviewDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams()
  const uId = searchParams.get('userId')
  console.log(id, "id");
  console.log(uId, "userId");

  const [getDeatils, { isLoading }] = useLazyGetRatingByIdQuery();
  const [ratingData, setRatingdata] = useState<ratingType>();

  const fetchById = async () => {
    try {
      const response = await getDeatils({ id: id, userId: uId }).unwrap();
      if (response?.statusCode === 200) {
        setRatingdata(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    fetchById();
  }, [id, uId])

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Review Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate(`/manage-products/details/${id}`);
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid item xs={10} className="view_box_list">
              <Grid container spacing={3}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Review By</Typography>
                    <Typography component="p">{ratingData?.userId?.fullName}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Product Name
                    </Typography>
                    <Typography component="p">{ratingData?.productId?.name}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Category
                    </Typography>
                    <Typography component="p">{ratingData?.productId?.categoryId?.name}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Sub-category
                    </Typography>
                    <Typography component="p">{ratingData?.productId?.subCategoryId?.name}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Rating</Typography>
                    <Typography component="p"><Rating name="read-only" value={ratingData?.rating} readOnly /></Typography>
                  </Box>
                </Grid>

                <Grid item lg={9} md={9} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Review</Typography>
                    <Typography component="p">
                      {ratingData?.review}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ReviewDetails;
