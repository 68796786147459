import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";
import { isValidInput } from "../../utils/validations";
import { useLazyGetPaymentsQuery } from "../../services/payments";
import Loader from "../../constants/Loader";
import { showError } from "../../constants/toast";
import { PaymentType } from "../../types/General";
import { ExportModal3, Pagination } from "../../components";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";


const Payments = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [getPayments, { isLoading }] = useLazyGetPaymentsQuery();
  const [paymentsData, setPaymentsData] = useState<PaymentType[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const today = new Date().toISOString().split('T')[0];
  let totalPages = Math.ceil(totalCount / 10);
  const [open, setOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const handleDateChange = (event: any) => {
    setStartDate(event);
  };

  const handleDateChange1 = (event: any) => {
    setEndDate(event);
  };

  function convertToInternationalCurrencySystem(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }

  const fetchPaymentsData = async () => {
    try {
      const response = await getPayments({
        query: debouncedSearchTerm.trim(),
        page: page,
        size: 10,
        fromDate: startDate ? moment(startDate).format(
          'YYYY-MM-DDTHH:mm:ss[Z]',
        ) : "",
        toDate: endDate ? moment(endDate).format(
          'YYYY-MM-DDTHH:mm:ss[Z]',
        ) : "",
      },).unwrap();
      if (response?.statusCode === 200) {
        setPaymentsData(response?.data?.data)
        setTotalCount(response?.data?.count || 0)
      }
      else {
        setPaymentsData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  console.log(paymentsData, "paymentsData.........");


  useEffect(() => {
    fetchPaymentsData();
  }, [page, debouncedSearchTerm, searchTerm, startDate, endDate]);

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />
      <Box className="cards_header">
        <SearchBar
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          value={searchTerm}
          onCross={() => setSearchTerm("")}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }
          }
        />

      </Box>
      <Box className="crds_date" >
            <div>
              <Typography>Start Date</Typography>
            
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={startDate}
                  className="adapter_picker"
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            </div>
            <div>
              <Typography>End Date</Typography>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={endDate}
                  className="adapter_picker"
                  onChange={handleDateChange1}

                />
              </LocalizationProvider>
            </div>
            {startDate || endDate ? (
              <Button
                onClick={() => { setStartDate(null); setEndDate(null) }}
                style={{ marginLeft: "1%", borderRadius: "25px", background: "gray",marginTop:"2%" }}
                className="btn btn_primary">Clear</Button>
            ) : ("")}
          </Box>
      <TableContainer className="table_container">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Order Id</TableCell>
              <TableCell align="center">Transaction Id</TableCell>
              <TableCell align="center">Total Payment</TableCell>
              <TableCell align="center">Payment Received</TableCell>
              <TableCell align="center">Balance Payment</TableCell>
              <TableCell align="center">Payment Mode</TableCell>
              <TableCell align="center">Customer Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Phone Number</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentsData?.length ? (
              paymentsData.map((row, i) => (
                <TableRow key={row?._id}>
                  <TableCell align="center"> {(page - 1) * 10 + i + 1}</TableCell>
                  <TableCell align="center">{row?.orderId}</TableCell>
                  <TableCell align="center">{row?.transactionId || "-"}</TableCell>
                  <TableCell align="center">AED{" "}{convertToInternationalCurrencySystem(row?.totalPriceOfProduct)}</TableCell>
                  <TableCell align="center">AED{" "}{convertToInternationalCurrencySystem(row?.advanceAmountPaid)}</TableCell>
                  <TableCell align="center">AED{" "}{convertToInternationalCurrencySystem(row?.pendingAmountLeft)}</TableCell>
                  <TableCell align="center">
                    {(() => {
                      switch (row?.paymentMode) {
                        case 1:
                          return "Card";
                        case 2:
                          return "Wallet";
                        case 3:
                          return "Cardwallet";
                        case 4:
                          return "Walletcash";
                        default:
                          return "-";
                      }
                    })()}
                  </TableCell>
                  <TableCell align="center">{row?.user?.fullName  ? row?.user?.fullName :row?.user?.firstName+" "+row?.user?.lastName  }</TableCell>
                  <TableCell align="center">{row?.user?.email ||"-"}</TableCell>
                  <TableCell align="center">{row?.user?.countryCode
                    ? (row?.user?.countryCode.includes("+") ? "" : "+") +
                    row?.user?.countryCode
                    : null}{" "}
                    {row?.user?.phone || "-"}</TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <Tooltip title="View">
                        <IconButton
                          onClick={() =>
                            navigate(`/manage-revenue/payment-details/${row?._id}`)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={10}
                  sx={{ color: "#051140" }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        module={paymentsData}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <ExportModal3
        open={open}
        setOpen={setOpen}
      />
    </div >

  );
};

export default Payments;
