import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLazyGetBannerByIdQuery, usePostBannerMutation, useUpdateBannerByIdMutation } from "../../services/banners";
import { UploadMedia } from "../../utils/mediaUpload";
import { showError, showToast } from "../../constants/toast";
import { BannerType, CommonBody, GeofenceData, ProductData, TruckCategory, subCategoryType } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useLazyGetCategoriesQuery, useLazyGetSubCategoriesQuery } from "../../services/categories";
import { useLazyGetProductsBySubCategoryIdQuery, useLazyGetProductsWithoutPageQuery } from "../../services/products";
import { useDebouncedCallback } from "use-debounce";
import { useLazyGetGeofenceQuery } from "../../services/geofence";
import Multiselect from "multiselect-react-dropdown";

const AddBanner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [banner, setBanner] = useState<BannerType>();
  const [getBannerById] = useLazyGetBannerByIdQuery();
  const [updateBanner] = useUpdateBannerByIdMutation();
  const [addBanner] = usePostBannerMutation();
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [offerType, setOfferType] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const selectRef = useRef<any>(null);
  const [page, setPage] = useState<number>(1);
  const [image, setImage] = useState<string>("");
  const [getAllCategories] = useLazyGetCategoriesQuery();
  const [getAllSubCategories] = useLazyGetSubCategoriesQuery();
  const [getAllProductsById] = useLazyGetProductsBySubCategoryIdQuery();
  const [productData, setProductData] = useState<ProductData[]>([]);
  const [subData, setSubData] = useState<subCategoryType[]>([]);
  const [categoryData, setCategoryData] = useState<TruckCategory[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([])
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedGeofence, setSelectedGeofence] = useState<string>("");
  const [getAllGeofence] = useLazyGetGeofenceQuery();
  const [geoData, setGeoData] = useState<GeofenceData[]>([]);
  const [getAllProductsWithoutPage] = useLazyGetProductsWithoutPageQuery();
  console.log(selectedProducts, "selectedProducts");


  const getCategory = async () => {
    try {
      const response = await getAllCategories({
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data?.category || []);
      } else {
        setCategoryData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const fetchGeofence = async () => {
    try {
      const res = await getAllGeofence({
        page: page,
        size: 50,
        query: debouncedSearchTerm.trim()
      }).unwrap();
      if (res?.statusCode === 200) {
        setGeoData(res?.data?.data)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }

  console.log(selectedProducts, "setSelectedProducts");



  const getSubCategory = async (id: string) => {
    try {
      const response = await getAllSubCategories({
        id: selectedCategory,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubData(response?.data?.subCategory || []);
      } else {
        setSubData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };


  const getProducts = async (id: string) => {
    try {
      const res = await getAllProductsById({
        page: page,
        size: 10,
        id: selectedSubCategory
      }).unwrap();
      if (res?.statusCode === 200) {
        console.log(res?.data, "All Data product ")
        console.log(res?.data?.product, "productdata");
        setProductData(res?.data?.product || [])

      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }



  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const handleChangeCategory = (event: any) => {
    const value = event.target.value;
    setSelectedCategory(value);
  };
  const handleChangeSub = (event: any) => {
    const value = event.target.value;
    setSelectedSubCategory(value);
  };
  const handleChangeGeofence = (event: any) => {
    const value = event.target.value;
    setSelectedGeofence(value);
  };

  const handleOfferChange = (event: SelectChangeEvent) => {
    setOfferType(event.target.value);
  };

  const getBannerDetails = async (id: string | undefined) => {
    try {
      const res = await getBannerById({ id }).unwrap();
      if (res?.statusCode === 200) {
        setBanner(res?.data);
        setImage(res?.data?.image);
        setSelectedGeofence(res?.data?.geofenceId || "")
        setSelectedCategory(res?.data?.categoryId || "")
        setSelectedSubCategory(res?.data?.subCategoryId || "")
        setSelectedProducts(res?.data?.productIdArray || "")
        setOfferType(String(res?.data?.discountType) || "")
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: banner?.image || " ",
      title: banner?.title || "",
      title_ar: banner?.title_ar || "",
      discount: banner?.discount || "",
      startTime: banner?.startTime ? new Date(banner.startTime).toISOString().slice(0, 16) : "",
      endTime: banner?.endTime ? new Date(banner.endTime).toISOString().slice(0, 16) : "",

    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
      title_ar: Yup.string()
        .required("Title is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),

      discount: Yup.number()
        .required("Discount is required"),

      // discount: Yup.number()
      //   .when("offerType", {
      //     is: "1",
      //     then: Yup.number().max(100, "Discount cannot exceed 100%").required("Discount is required"),
      //     otherwise: Yup.number().required("Discount is required"),
      //   }),

      startTime: Yup.string()
        .required("Starting date is required")
        .test("is-valid-start-time", "Start date must be today or in the future", function (value) {
          const today = new Date().setHours(0, 0, 0, 0);
          const startTime = new Date(value).setHours(0, 0, 0, 0);
          return startTime >= today;
        }),

      endTime: Yup.string()
        .required("Ending date is required")
        .test("is-valid-end-time", "End date must be after or equal to start date", function (value) {
          const startTime = new Date(this.parent.startTime).setHours(0, 0, 0, 0);
          const endTime = new Date(value).setHours(0, 0, 0, 0);
          return endTime >= startTime;
        }),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let data = {
        title: values.title,
        title_ar: values.title_ar,
        image: image,
        discount: values.discount,
        startTime: values.startTime,
        endTime: values.endTime,
        discountType: offerType,
        categoryId: selectedCategory,
        subCategoryId: selectedSubCategory,
        geofenceId: selectedGeofence,
        productIdArray: selectedProducts,
      };
      console.log(data, "data")


      if (!image) {
        showError("Image is required")
        return;
      }
      if (!selectedCategory) {
        showError("Category is required")
        return;
      }
      if (!selectedGeofence) {
        showError("Geofence is required")
        return;
      }
      if (!selectedSubCategory) {
        showError("Subcategory is required")
        return;
      }

      if (!offerType) {
        showError("Discount type is required")
        return;
      }

      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateBanner({ body: encryptedBody, id }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Banner updated successfully!");
            navigate("/ad-banner-management", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addBanner(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Banner added successfully!");
            navigate("/ad-banner-management", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const formattedAmenities = [...productData?.map(user => ({
    value: user._id,
    name: user.name,
  }))];

  const preSelectedValues = productData
    .filter(item => selectedProducts.includes(item?._id))
    .map(item => ({
      value: item?._id,
      name: item?.name,
    }));


  // Function to handle onSelect event
  const handleSelect = (selectedList: any, selectedItem: any) => {
    setSelectedProducts(selectedList?.map((option: { value: any; }) => option?.value))
  };

  // Function to handle onRemove event
  const handleRemove = (selectedList: any, removedItem: any) => {
    setSelectedProducts(selectedList?.map((option: { value: any; }) => option?.value))
  };



  useEffect(() => {
    if (id) {
      getBannerDetails(id);
    }
  }, []);

  useEffect(() => {
    getCategory();
  }, [])

  useEffect(() => {
    if (selectedCategory) {
      getSubCategory(selectedCategory)
    }
  }, [selectedCategory])

  useEffect(() => {
    if (selectedSubCategory) {
      getProducts(selectedSubCategory)
    }
  }, [selectedSubCategory])

  useEffect(() => {
    fetchGeofence()
  }, [])



  return (
    <>
      <div>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Banner" : "Add Banner"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/ad-banner-management");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview2">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e)}
                          />
                          <Button component="span" >
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/prod_placeholder.png"
                              }
                              alt=""
                            />
                            <AddIcon width={"100%"} height={"100%"} />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Banner Title (English)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      id="title"
                      value={formik.values.title}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      className="text_field"
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.title && formik.errors.title}
                      inputProps={{ maxLength: 30 }}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Banner Title (Arabic)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title_ar"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      className="text_field"
                      id="title_ar"
                      onBlur={formik.handleBlur}
                      value={formik.values.title_ar}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.title_ar && formik.errors.title_ar}
                      inputProps={{ maxLength: 30 }}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Add Geofence
                      </Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        value={selectedGeofence}
                        onChange={handleChangeGeofence}

                      // sx={{ minWidth: "250px" }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {geoData?.filter(item => !item?.isBlocked).map((item, index) => (
                          <MenuItem value={item?._id}>{item?.geofenceName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Product category
                      </Typography>{" "}
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        value={selectedCategory}
                        onChange={handleChangeCategory}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {categoryData?.map((item, index) => (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Product Sub-category
                      </Typography>{" "}
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        value={selectedSubCategory}
                        disabled={subData.length === 0 ? true : false}
                        onChange={handleChangeSub}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {subData?.map((item, index) => (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Product Name
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Multiselect
                        className='users_autocomplete'
                        options={formattedAmenities}
                        displayValue="name"
                        onSelect={handleSelect}
                        onRemove={handleRemove}
                        selectedValues={id ? (preSelectedValues) : []}
                      />

                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Discount Type</Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                        value={offerType}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        onChange={handleOfferChange}
                      >
                        <MenuItem value="" disabled>Select</MenuItem>
                        <MenuItem value="1">Percentage</MenuItem>
                        <MenuItem value="2">Flat</MenuItem>
                        {/* <MenuItem value="3">Both</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Discount
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="discount"
                      id="discount"
                      variant="outlined"
                      fullWidth
                      placeholder="Discount"
                      className="text_field"
                      inputProps={{ maxLength: offerType === '1' ? 2 : 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.discount}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.discount && formik.errors.discount}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      Start time
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="date"
                      name="startTime"
                      variant="outlined"
                      fullWidth
                      placeholder="Start Time"
                      className="text_field"
                      // inputProps={{ maxLength: 30 }}
                      inputProps={{ 
                        maxLength: 30, 
                        min: new Date().toISOString().split("T")[0] 
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.startTime}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.startTime && formik.errors.startTime}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label"> End time</Typography>
                    <TextField
                      hiddenLabel
                      type="date"
                      name="endTime"
                      variant="outlined"
                      fullWidth
                      placeholder="End Time"
                      className="text_field"
                      // inputProps={{ maxLength: 30 }}
                      inputProps={{ 
                        maxLength: 30, 
                        min: formik.values.startTime ? formik.values.startTime : new Date().toISOString().split("T")[0] 
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.endTime}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.endTime && formik.errors.endTime}
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddBanner;
