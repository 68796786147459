import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";

// import "../Users.scss";
import { useLazyGetBulkOrderByIdQuery } from "../../services/orders";
import { showError } from "../../constants/toast";
import { BulkOrderData } from "../../types/General";
import MainContainer from "../../layout/MainContainer";


const BulkOrderDetails = () => {
    const navigate = useNavigate();
    const [getUserById] = useLazyGetBulkOrderByIdQuery();
    const { id } = useParams();
    const [data, setData] = useState<BulkOrderData>();
    const [viewAll, setViewAll] = useState<boolean>(false);
    const buttonText = viewAll ? "Show Less" : "View All";
    const fetchById = async () => {
        try {
            const response = await getUserById({ id }).unwrap();
            if (response?.statusCode === 200) {
                setData(response?.data);
            }
        } catch (error: any) {
            console.log(error, "errror");
            showError(error?.data?.message || "");
        }
    };

    function convertToInternationalCurrencySystem(labelValue: number) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e9
            ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
            : // Six Zeroes for Millions
            Math.abs(Number(labelValue)) >= 1.0e6
                ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
                : // Three Zeroes for Thousands
                Math.abs(Number(labelValue)) >= 1.0e3
                    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
                    : Math.abs(Number(labelValue));
    }


    useEffect(() => {
        if (id) {
            fetchById();
        }
    }, []);



    return (
        <div>
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">View Order Details</h1>
                    <Box className="cards_header_right">
                        <Button
                            className="btn btn_primary"
                            onClick={() => {
                                navigate("/manage-bulk-orders");
                            }}
                        >
                            Back
                        </Button>
                    </Box>
                </div>
                <Card className="cards">
                    <CardContent sx={{ p: 1 }}>
                        <h4>Product details</h4>
                        <ul className="prdct_images">
                            {data?.productId?.images?.length ? (
                                <>
                                    {data?.productId?.images?.slice(0, viewAll ? data?.productId?.images.length : 5).map((item, i) => {
                                        return (
                                            <li key={i}>
                                                {item.type === 'image' ? (
                                                    <figure>
                                                        <img src={item.src} alt="upload" />
                                                    </figure>
                                                ) : (
                                                    <figure>
                                                        <video controls preload="metadata" autoPlay={false} width="100%" height="100%" style={{ objectFit: "cover", borderRadius: "20px" }}>
                                                            <source src={item.src} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </figure>
                                                )}
                                            </li>
                                        );
                                    })}

                                    {data?.productId?.images?.length > 5 && (
                                        <Button
                                            className="btn btn_primary"
                                            onClick={() => {
                                                setViewAll(!viewAll)
                                            }}
                                        >
                                            {buttonText}
                                        </Button>
                                    )}
                                </>
                            ) : null}
                        </ul>
                        <Grid container spacing={2} className="view_box">
                            <Grid item xs={10} className="view_box_list">
                                <Grid container spacing={3}>
                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <Box>
                                            <Typography component="h5">Customer Name</Typography>
                                            <Typography component="p">{data?.userId?.fullName ? data?.userId?.fullName : data?.userId?.firstName + " " + data?.userId?.lastName} </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <Box>
                                            <Typography component="h5">Email</Typography>
                                            <Typography component="p" >
                                                {data?.userId?.email || "-"}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <Box>
                                            <Typography component="h5">Phone no</Typography>
                                            <Typography component="p">{data?.userId?.countryCode
                                                ? (data?.userId?.countryCode.includes("+") ? "" : "+") +
                                                data?.userId?.countryCode
                                                : null}{" "}
                                                {data?.userId?.phone || "-"}</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <Box>
                                            <Typography component="h5">Product Name</Typography>
                                            <Typography component="p">{data?.productName || "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Product Category</Typography>
                                            <Typography component="p">{data?.categoryName || "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Product Sub-Category</Typography>
                                            <Typography component="p">{data?.subCategoryName || "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <Box>
                                            <Typography component="h5">Quantity</Typography>
                                            <Typography component="p">{data?.quantity}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <Box>
                                            <Typography component="h5">Total Cost</Typography>
                                            <Typography component="p">AED{" "}{convertToInternationalCurrencySystem(data?.totalPrice || 0)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box>
                                            <Typography component="h5">Delivery Address</Typography>
                                            <Typography component="p">{data?.address?.address}</Typography>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default BulkOrderDetails;
