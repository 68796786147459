import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Card, Box, Button, Tabs, Tab, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch, IconButton, Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import { isValidInput } from "../../utils/validations";
import { useDeleteVarientMutation, useLazyGetAllVarientsQuery, useUpdateVarientMutation } from "../../services/varint";
import { CommonBody, varients } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import { Pagination, WarnModal } from "../../components";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../constants/Loader";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageSizes = () => {
  const navigate = useNavigate();
  const UserData = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [sizeData, setSizeData] = useState<varients[]>();
  const [updateVarient] = useUpdateVarientMutation();
  const [deleteVarient] = useDeleteVarientMutation();
  const [getAllVarients, { isLoading }] = useLazyGetAllVarientsQuery();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedId, setSelectedId] = useState<string>("");
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);


  //update size status api
  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateVarient({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Size status updated successfully");
        await getAllVarient();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating size status");
    }
  };

  //get all varients 
  const getAllVarient = async () => {
    try {
      const response = await getAllVarients({
        page: page,
        query: debouncedSearchTerm.trim()
      },).unwrap();
      if (response?.statusCode === 200) {
        setTotalCount(response?.data?.count);
        setSizeData(response?.data?.variant || [])
        console.log(sizeData, "sizeData")
      }
      else {
        setSizeData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  //delete api
  const handleDeleteSize = async (id: string) => {
    try {
      const res = await deleteVarient({ id }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Size deleted successfully");
        await getAllVarient();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.message || "");
    }
  };

  useEffect(() => {
    getAllVarient();
  }, [page, debouncedSearchTerm,]);

  const checkPermission = () => {
    const permission = UserData?.permissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Sizes"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };


  useEffect(() => {
    checkPermission();
  }, [UserData])

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Sizes</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || UserData?.role === 1 ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-sizes/add-size", {
                      state: { data: "add" }
                    })}
                  >
                    Add Size
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center"> Size</TableCell>
                    <TableCell align="center"> Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {
                  sizeData?.length ? (
                    sizeData?.map((row: varients, i: number) => {
                      return (
                        <TableBody key={row?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {" "}
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell align="center">
                              {row?.value + " " + row?.label}
                            </TableCell>
                            <TableCell align="center">
                              <Switch
                                {...label}
                                size="small"
                                checked={!row?.isBlocked}
                                onChange={() =>
                                  updateUserStatus(row?._id, !row?.isBlocked)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Box className="table_actions">
                                {hidePermission?.isEdit || UserData?.role === 1 ? (
                                  <Tooltip title="Edit">
                                    <IconButton
                                      onClick={() => navigate(`/manage-sizes/edit-size/${row?._id}`, {
                                        state: { data: "edit" }
                                      })}
                                    >
                                      <ModeEditIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                                {hidePermission?.isDelete || UserData?.role === 1 ? (
                                  <Tooltip title="Delete">
                                    <IconButton>
                                      <DeleteIcon
                                        onClick={() => {
                                          setOpen(true);
                                          setSelectedId(row?._id);
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (<TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      No data Found
                    </TableCell>
                  </TableBody>
                  )
                }

              </Table>
            </TableContainer>
          </Box>
        </Card>
        <Pagination
          module={sizeData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        name="size"
        setOpen={setOpen}
        open={open}
        handleDelete={() => handleDeleteSize(selectedId)}
      />
    </div>
  );
};

export default ManageSizes;
