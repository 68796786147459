import React, { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import ClearIcon from "@mui/icons-material/Clear";
import { WarnModal } from "../../components";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  // withStyles,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-phone-input-2/lib/bootstrap.css";
import AddIcon from "@mui/icons-material/Add";
// import md5 from 'md5';
// import { MD5 } from 'crypto-js';
import {
  CommonBody,
  ProductData,
  TruckCategory,
  subCategoryType,
  varients,
} from "../../types/General";
import {
  useLazyGetCategoriesQuery,
  useLazyGetSubCategoriesQuery,
} from "../../services/categories";
import DeleteIcon from "@mui/icons-material/Delete";
import { isNumber, isNumberPrice, isString } from "../../utils/validations";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  useAddProductMutation,
  useLazyProductDetailsQuery,
  useUpdateProductsMutation,
} from "../../services/products";
import { useLazyGetVarientsQuery } from "../../services/varint";
import Loader from "../../constants/Loader";
import { useNavigate, useParams } from "react-router-dom";

type Variation = {
  totalQuantity: string;
  maxPurchaseQuantity: string;
  minPurchaseQuantity: string;
  price: any;
  size: string;
  size_ar: string;
};

const AddProductForm = () => {
  const [errorr, setError] = useState<boolean>(false)
  const navigate = useNavigate();
  const [arabic, setArabic] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();
  const [viewAll, setViewAll] = useState<boolean>(false);
  const [myImages, setMyImages] = useState<any[]>([]);
  const [category, setCategory] = useState<TruckCategory[]>([]);
  const [getAllCategories] = useLazyGetCategoriesQuery();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedCategory_ar, setSelectedCategory_ar] = useState<string>("");
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>("");
  const [selectedSubCategory_ar, setSelectedSubCategory_ar] = useState<string>("");
  const [displayVariant, setDisplayVariant] = React.useState(id ? false : true);
  const [getSubCategory] = useLazyGetSubCategoriesQuery();
  const [subCategory, setSubCategory] = useState<subCategoryType[]>([]);
  const [addProduct] = useAddProductMutation();
  const [getVarient] = useLazyGetVarientsQuery();
  const [varients, setVarients] = useState<varients[]>([]);
  const [getProducts] = useLazyProductDetailsQuery();
  const [product, setProduct] = useState<ProductData>();
  const [updateProducts] = useUpdateProductsMutation();
  const buttonText = viewAll ? "Show Less" : "View All";
  const [open, setOpen] = React.useState(false);
  const [categoryError, setCategoryError] = React.useState(false);
  const [category_arError, setCategory_arError] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(true);
  //states for check box
  const [showOuterDiameter, setShowOuterDiameter] = useState<boolean>(false);
  const [showInnerDiameter, setShowInnerDiameter] = useState<boolean>(false);
  const [showWidth, setShowWidth] = useState<boolean>(false);
  const [showApplication, setShowApplication] = useState<boolean>(false);
  const [showOverallHeight, setShowOverallHeight] = useState<boolean>(false);
  const [showBearingClearance, setShowBearingClearance] = useState<boolean>(false);
  const [showAxialClearance, setShowAxialClearance] = useState<boolean>(false);
  const [showRollerDiameter, setShowRollerDiameter] = useState<boolean>(false);
  const [showPitchDiameter, setShowPitchDiameter] = useState<boolean>(false);
  const [showContactAngle, setShowContactAngle] = useState<boolean>(false);
  const [showRunout, setShowRunout] = useState<boolean>(false);
  const [showWeight, setShowWeight] = useState<boolean>(false);
  const [showOverallLength, setShowOverallLength] = useState<boolean>(false);
  const [showBoltHoleSpacing, setShowBoltHoleSpacing] = useState<boolean>(false);
  const [showBoltHoleDiameter, setShowBoltHoleDiameter] = useState<boolean>(false);
  const [showShaftDiameter, setShowShaftDiameter] = useState<boolean>(false);
  const [showMountingHeight, setShowMountingHeight] = useState<boolean>(false);
  const [showDistanceFromBaseToCenter, setShowDistanceFromBaseToCenter] = useState<boolean>(false);
  const [showThreadQuality, setShowThreadQuality] = useState<boolean>(false);
  const [showSurfaceFinish, setShowSurfaceFinish] = useState<boolean>(false);
  const [showAntiCorrosionProperties, setShowAntiCorrosionProperties] = useState<boolean>(false);
  const [showThreadLocking, setShowThreadLocking] = useState<boolean>(false);
  const [showCompatibilityAndFitment, setShowCompatibilityAndFitment] = useState<boolean>(false);
  const [showTestingAndQualityControl, setShowTestingAndQualityControl] = useState<boolean>(false);
  const [showPrecisionRating, setShowPrecisionRating] = useState<boolean>(false);
  const [showMaterialComposition, setShowMaterialComposition] = useState<boolean>(false);
  const [showManufacturingStandards, setShowManufacturingStandards] = useState<boolean>(false);
  const [showLoadCapacity, setShowLoadCapacity] = useState<boolean>(false);
  const [showSealingAndLubrication, setShowSealingAndLubrication] = useState<boolean>(false);
  const [showToleranceAndFit, setShowToleranceAndFit] = useState<boolean>(false);
  const [showDurabilityAndLifespan, setShowDurabilityAndLifespan] = useState<boolean>(false);
  const [showSpeedRpm, setShowSpeedRpm] = useState<boolean>(false);
  const [showNoiseAndVibration, setShowNoiseAndVibration] = useState<boolean>(false);
  const [showPackagingType, setShowPackagingType] = useState<boolean>(false);
  const [showMarking, setShowMarking] = useState<boolean>(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  console.log(myImages, "myImages");
  const fetchDetails = async (id: string) => {
    try {
      const res = await getProducts({
        id: id,
      }).unwrap();

      if (res?.statusCode === 200) {
        setProduct(res?.data || []);
        setSelectedCategory(res?.data?.category?._id);
        setSelectedCategory_ar(res?.data?.category?.name_ar);
        setSelectedSubCategory(res?.data?.subcategory?._id);
        setSelectedSubCategory_ar(res?.data?.subcategory?.name_ar)
        setShowOuterDiameter(res?.data?.outerDiameterBoolean);
        setShowInnerDiameter(res?.data?.innerDiameterBoolean);
        setShowWidth(res?.data?.widthBoolean);
        setShowApplication(res?.data?.applicationBoolean);
        setShowOverallHeight(res?.data?.overallHeightBoolean);
        setShowBearingClearance(res?.data?.bearingClearanceBoolean);
        setShowAxialClearance(res?.data?.axialClearanceBoolean);
        setShowRollerDiameter(res?.data?.rollerDiameterBoolean);
        setShowPitchDiameter(res?.data?.pitchDiameterBoolean);
        setShowContactAngle(res?.data?.contactAngleBoolean);
        setShowRunout(res?.data?.runoutBoolean);
        setShowWeight(res?.data?.weightBoolean);
        setShowOverallLength(res?.data?.overallLengthBoolean);
        setShowBoltHoleSpacing(res?.data?.boltHoleSpacingBoolean);
        setShowBoltHoleDiameter(res?.data?.boltHoleDiameterBoolean);
        setShowShaftDiameter(res?.data?.shaftDiameterBoolean);
        setShowMountingHeight(res?.data?.mountingHeightBoolean);
        setShowDistanceFromBaseToCenter(res?.data?.distanceFromBaseToCenterBoolean);
        setShowThreadQuality(res?.data?.threadQualityBoolean);
        setShowSurfaceFinish(res?.data?.surfaceFinishBoolean);
        setShowAntiCorrosionProperties(res?.data?.antiCorrosionPropertiesBoolean);
        setShowThreadLocking(res?.data?.threadLockingBoolean);
        setShowCompatibilityAndFitment(res?.data?.compatibilityAndFitmentBoolean);
        setShowTestingAndQualityControl(res?.data?.testingAndQualityControlBoolean);
        setShowPrecisionRating(res?.data?.precisionRatingBoolean);
        setShowMaterialComposition(res?.data?.materialCompositionBoolean);
        setShowManufacturingStandards(res?.data?.manufacturingStandardsBoolean);
        setShowLoadCapacity(res?.data?.loadCapacityBoolean);
        setShowSealingAndLubrication(res?.data?.sealingAndLubricationBoolean);
        setShowToleranceAndFit(res?.data?.toleranceAndFitBoolean);
        setShowDurabilityAndLifespan(res?.data?.durabilityAndLifespanBoolean);
        setShowSpeedRpm(res?.data?.speedRpmBoolean);
        setShowNoiseAndVibration(res?.data?.noiseAndVibrationBoolean);
        setShowPackagingType(res?.data?.packagingTypeBoolean);
        setShowMarking(res?.data?.markingBoolean);
        setArabic(res?.data?.dataInArabic)


        const newData = res?.data?.productVariants?.map((item) => {
          return {
            totalQuantity: String(item.totalQuantity),
            maxPurchaseQuantity: String(item.maxPurchaseQuantity),
            minPurchaseQuantity: String(item.minPurchaseQuantity),
            price: String(item.price),
            size: String(item?.size),
          };
        });
        const images = res?.data?.images?.map((item) => {
          return item;
        });
        setMyImages(images);
        setVariations((prev: any) => [...prev, ...newData]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const [variations, setVariations] = useState<Variation[]>([]);

  const getAllVarients = async () => {
    try {
      const res = await getVarient({}).unwrap();
      if (res?.statusCode === 200) {
        setVarients(res?.data?.variant);
      }
    } catch (error: any) {
      showError(error);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    const allowedVideoTypes = ["video/mp4"];

    if (files) {
      const uploadPromises = Array.from(files).map(async (file) => {
        if (allowedImageTypes.includes(file.type)) {
          // Handle image upload
          try {
            const res = await UploadMedia(file);
            return { src: res.data, type: 'image' };
          } catch (error) {
            console.error("Error uploading image:", error);
            return null;
          }
        } else if (allowedVideoTypes.includes(file.type)) {
          // Handle video upload
          try {
            const res = await UploadVideo(file);
            if (res?.statusCode === 200) {

              const thumbnail = await generateThumbnail(file);

              const thumbnailByteCharacters = atob(thumbnail.split(",")[1]);
              const thumbnailByteNumbers = new Array(
                thumbnailByteCharacters.length
              );
              for (let i = 0; i < thumbnailByteCharacters.length; i++) {
                thumbnailByteNumbers[i] = thumbnailByteCharacters.charCodeAt(i);
              }
              const thumbnailByteArray = new Uint8Array(thumbnailByteNumbers);
              const thumbnailBlob = new Blob([thumbnailByteArray], {
                type: "image/jpeg",
              });
              // Upload the video
              // const videoUploadResponse = await UploadVideo(thumbnailBlob);
              const thumbnailUploadResponse = await UploadMedia(thumbnailBlob);
              console.log(thumbnailUploadResponse, "thumbnailUploadResponse");
              // const thumbnailUrl = await saveThumbnail(thumbnail);
              const thumbnailUrl = thumbnailUploadResponse?.data
              return { src: res.data, type: 'video', thumbnailUrl };
            }

          } catch (error) {
            console.error("Error uploading video:", error);
            return null;
          }
        } else {
          // File type not supported
          console.error("Unsupported file type");
          return null;
        }
      });

      try {
        const uploadedFiles = await Promise.all(uploadPromises);
        // Filter out null values (errors) and add only successful uploads to myImages state
        const successfulUploads = uploadedFiles.filter(file => file !== null);
        setMyImages((prev: any) => [...prev, ...successfulUploads]);
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    }
  };



  const generateThumbnail = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const video = document.createElement("video");
      video.muted = true;
      video.preload = "metadata";
      video.addEventListener("loadedmetadata", async () => {
        const duration = Math.min(video.duration, 1); // Ensure duration is at least 1 second
        video.currentTime = duration; // Set the currentTime to 1 second after the video starts
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
        const canvas = document.createElement("canvas");
        canvas.width = 200; // Set thumbnail width
        canvas.height = 150; // Set thumbnail height
        const context = canvas.getContext("2d");
        if (context) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL());
        } else {
          reject(new Error("Thumbnail generation failed"));
        }
        video.remove();
        canvas.remove();
      });
      video.addEventListener("error", (error) => {
        reject(error);
      });
      video.src = URL.createObjectURL(file); // Set the video source
      document.body.appendChild(video); // Append the video element to the document to trigger metadata loading
    });
  };


  const getAllSubCategory = async (id: string) => {
    try {
      const res = await getSubCategory({ id: id }).unwrap();
      ////////      // console.log(res);
      if (res?.statusCode === 200) {
        setSubCategory(res?.data?.subCategory);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const getCategory = async () => {
    try {
      const response = await getAllCategories({}).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data?.category || []);
      } else {
        setCategory([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");

    }
  };

  const [variationList, setVaritaionList] = React.useState(variations);
  React.useEffect(() => {
    setVaritaionList(variations);
  }, [variations])

  const addVariations = (values: any, save: String) => {
    if (save === 'addVariations') {
      formik2.touched.maxlimit = true;
      formik2.touched.productlimit = true;
      formik2.touched.minlimit = true;
      formik2.touched.productCost = true;
      formik2.touched.size = formik2.values.size == "" ? true : false;
      formik2.setFieldValue("productlimit", formik2.values.productlimit);
      formik2.setFieldValue("minlimit", formik2.values.minlimit);
      formik2.setFieldValue("maxlimit", formik2.values.maxlimit);
      formik2.setFieldValue("productCost", formatNumberWithCommas(formik2.values.productCost));
      setError(true);
    }
    else {
      formik2.touched.size = false;
    }
    if (formik2.values.productlimit === "" || formik2.values.minlimit === "" || formik2.values.maxlimit === "" || formik2.values.productCost === "" || formik2.values.size == '' || !formik2.isValid) {
      return;
    }
    setDisplayVariant(false);

    formik2.touched.maxlimit = false;
    formik2.touched.productlimit = false;
    formik2.touched.minlimit = false;
    formik2.touched.productCost = false;
    formik2.setFieldValue("size", "");
    formik2.setFieldValue("productlimit", "");
    formik2.setFieldValue("minlimit", "");
    formik2.setFieldValue("maxlimit", "");
    formik2.setFieldValue("productCost", "");
    const newData =
    // Spread existing variations
    {
      totalQuantity: values.productlimit,
      maxPurchaseQuantity: values.maxlimit,
      minPurchaseQuantity: values.minlimit,
      price: values.productCost ? formatNumberWithCommas(String(values.productCost)) : "",
      size: values.size,
      size_ar: values.size_ar,
    };
    setVariations((prevdata) => {
      return [newData, ...prevdata];
    });

    setError(false)

  };


  ////console.log(variationList);
  ////console.log(variations);

  const handleCrossClick = async (e: any, i: number) => {
    e.stopPropagation();
    ////////    // console.log(i);
    let arr = [...myImages];
    arr?.splice(i, 1);
    setMyImages([...arr]);
  };
  //console.log(selectedSubCategory);
  const handleChange = (event: any) => {
    const value = event.target.value;
    setSelectedCategory(value);
  };
  const handleChange_ar = (event: any) => {
    const value = event.target.value;
    setSelectedCategory_ar(value);
  };

  const handleSubCategory = (event: any) => {
    const value = event.target.value;
    setSelectedSubCategory(value);
  };
  const handleSubCategory_ar = (event: any) => {
    const value = event.target.value;
    setSelectedSubCategory_ar(value);
  };
  ////////  // console.log(selectedCategory, "cid");

  const handleDelete = (index: number) => {
    const newArray = [...variations];
    newArray.splice(index, 1);
    setVaritaionList(prevData => {
      const temp = [...prevData];
      temp.splice(index, 1);
      return temp;
    })
    setVariations(newArray);
    console.log(variations);

    console.log(variationList);
    if (variationList.length == 1) {
      setDisplayVariant(true);
    }
  }

  const formik2: any = useFormik({
    initialValues: {
      productlimit: "",
      maxlimit: "",
      minlimit: "",
      productCost: "",
      size: ""
    },
    validationSchema: Yup.object({
      productlimit: Yup.number()
        .typeError('Please enter a valid number')
        .positive('Must be a positive number')
        .required('Product quantity is required'),
      maxlimit: Yup.number()
        .required('Max quantity is required')
        .typeError('Please enter a valid number')
        .positive('Must be a positive number')
        .min(Yup.ref('minlimit'), 'Max quantity must be greater than min quantity')
        .max(Yup.ref('productlimit'), 'Max quantity must be less than product quantity'),
      minlimit: Yup.number()
        .typeError('Please enter a valid number')
        .positive('Must be a positive number')
        .required('Min quantity is required')
        .max(Yup.ref('maxlimit'), 'Min quantity must be less than Max quantity'),
      productCost: Yup.string()
        // .typeError('Please enter a valid number')
        // .positive('Must be a positive number')
        .required('Product cost is required'),
      size: Yup.string()
        .required('Product Size is required'),
    })
    ,
    onSubmit: (values) => {
      ////////      console.log(values);
    }
  })

  ////////  console.log(formik2.values);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: product?.productNo || "",
      name: product?.name || "",
      name_ar: product?.name_ar || "",
      category: product?.category?.name || "",
      category_ar: product?.category?.name_ar || "",
      subCategory_ar: product?.subcategory?.name_ar || "",
      description: product?.description || "",
      description_ar: product?.description_ar || "",
      outerDiameter: product?.outerDiameter || "",
      innerDiameter: product?.innerDiameter || "",
      width: product?.width || "",
      application: product?.application || "",
      overallHeight: product?.overallHeight || "",
      bearingClearance: product?.bearingClearance || "",
      axialClearance: product?.axialClearance || "",
      rollerDiameter: product?.rollerDiameter || "",
      pitchDiameter: product?.pitchDiameter || "",
      contactAngle: product?.contactAngle || "",
      runout: product?.runout || "",
      weight: product?.weight || "",
      overallLength: product?.overallLength || "",
      boltHoleSpacing: product?.boltHoleSpacing || "",
      boltHoleDiameter: product?.boltHoleDiameter || "",
      shaftDiameter: product?.shaftDiameter || "",
      mountingHeight: product?.mountingHeight || "",
      distanceFromBaseToCenter: product?.distanceFromBaseToCenter || "",
      threadQuality: product?.threadQuality || "",
      surfaceFinish: product?.surfaceFinish || "",
      antiCorrosionProperties: product?.antiCorrosionProperties || "",
      threadLocking: product?.threadLocking || "",
      compatibilityAndFitment: product?.compatibilityAndFitment || "",
      testingAndQualityControl: product?.testingAndQualityControl || "",
      precisionRating: product?.precisionRating || "",
      materialComposition: product?.materialComposition || "",
      manufacturingStandards: product?.manufacturingStandards || "",
      loadCapacity: product?.loadCapacity || "",
      sealingAndLubrication: product?.sealingAndLubrication || "",
      toleranceAndFit: product?.toleranceAndFit || "",
      durabilityAndLifespan: product?.durabilityAndLifespan || "",
      speedRpm: product?.speedRpm || "",
      noiseAndVibration: product?.noiseAndVibration || "",
      packagingType: product?.packagingType || "",
      marking: product?.marking || "",
      dataInArabic: product?.dataInArabic || "",

      //for arabic 
      outerDiameter_ar: product?.outerDiameter_ar || "",
      innerDiameter_ar: product?.innerDiameter_ar || "",
      width_ar: product?.width_ar || "",
      application_ar: product?.application_ar || "",
      overallHeight_ar: product?.overallHeight_ar || "",
      bearingClearance_ar: product?.bearingClearance_ar || "",
      axialClearance_ar: product?.axialClearance_ar || "",
      rollerDiameter_ar: product?.rollerDiameter_ar || "",
      pitchDiameter_ar: product?.pitchDiameter_ar || "",
      contactAngle_ar: product?.contactAngle_ar || "",
      runout_ar: product?.runout_ar || "",
      weight_ar: product?.weight_ar || "",
      overallLength_ar: product?.overallLength_ar || "",
      boltHoleSpacing_ar: product?.boltHoleSpacing_ar || "",
      boltHoleDiameter_ar: product?.boltHoleDiameter_ar || "",
      shaftDiameter_ar: product?.shaftDiameter_ar || "",
      mountingHeight_ar: product?.mountingHeight_ar || "",
      distanceFromBaseToCenter_ar: product?.distanceFromBaseToCenter_ar || "",
      threadQuality_ar: product?.threadQuality_ar || "",
      surfaceFinish_ar: product?.surfaceFinish_ar || "",
      antiCorrosionProperties_ar: product?.antiCorrosionProperties_ar || "",
      threadLocking_ar: product?.threadLocking_ar || "",
      compatibilityAndFitment_ar: product?.compatibilityAndFitment_ar || "",
      testingAndQualityControl_ar: product?.testingAndQualityControl_ar || "",
      precisionRating_ar: product?.precisionRating_ar || "",
      materialComposition_ar: product?.materialComposition_ar || "",
      manufacturingStandards_ar: product?.manufacturingStandards_ar || "",
      loadCapacity_ar: product?.loadCapacity_ar || "",
      sealingAndLubrication_ar: product?.sealingAndLubrication_ar || "",
      toleranceAndFit_ar: product?.toleranceAndFit_ar || "",
      durabilityAndLifespan_ar: product?.durabilityAndLifespan_ar || "",
      speedRpm_ar: product?.speedRpm_ar || "",
      noiseAndVibration_ar: product?.noiseAndVibration_ar || "",
      packagingType_ar: product?.packagingType_ar || "",
      marking_ar: product?.marking_ar || ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Product name is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);


      if (selectedCategory.length === 0) {
        showError("Select product category");
      }
      if (selectedSubCategory.length === 0) {
        showError("Select product sub-category");
      }
      if (variations.length <= 0) {
        showError("Please add varients")
        return;
      }


      if (id) {
        const variationsWithNumbers = variations.map((variation) => ({
          ...variation,
          totalQuantity: Number(variation.totalQuantity),
          maxPurchaseQuantity: Number(variation.maxPurchaseQuantity),
          minPurchaseQuantity: Number(variation.minPurchaseQuantity),
          price: variation.price ? Number(String(variation.price).replace(/,/g, '')) : 0,
          size: variation.size,
        }));

        const body = {
          name: values.name,
          description: values.description,
          images: myImages,
          categoryId: selectedCategory,
          subCategoryId: selectedSubCategory,
          variations: variationsWithNumbers,
          dataInArabic: arabic,

          outerDiameter: values.outerDiameter,
          innerDiameter: values.innerDiameter,
          width: values.width,
          application: values.application,
          overallHeight: values.overallHeight,
          bearingClearance: values.bearingClearance,
          axialClearance: values.axialClearance,
          rollerDiameter: values.rollerDiameter,
          pitchDiameter: values.pitchDiameter,
          contactAngle: values.contactAngle,
          runout: values.runout,
          weight: values.weight,
          overallLength: values.overallLength,
          boltHoleSpacing: values.boltHoleSpacing,
          boltHoleDiameter: values.boltHoleDiameter,
          shaftDiameter: values.shaftDiameter,
          mountingHeight: values.mountingHeight,
          distanceFromBaseToCenter: values.distanceFromBaseToCenter,
          threadQuality: values.threadQuality,
          surfaceFinish: values.surfaceFinish,
          antiCorrosionProperties: values.antiCorrosionProperties,
          threadLocking: values.threadLocking,
          compatibilityAndFitment: values.compatibilityAndFitment,
          testingAndQualityControl: values.testingAndQualityControl,
          precisionRating: values.precisionRating,
          materialComposition: values.materialComposition,
          manufacturingStandards: values.manufacturingStandards,
          loadCapacity: values.loadCapacity,
          sealingAndLubrication: values.sealingAndLubrication,
          toleranceAndFit: values.toleranceAndFit,
          durabilityAndLifespan: values.durabilityAndLifespan,
          speedRpm: values.speedRpm,
          noiseAndVibration: values.noiseAndVibration,
          packagingType: values.packagingType,
          marking: values.marking,

          outerDiameter_ar: values.outerDiameter_ar,
          innerDiameter_ar: values.innerDiameter_ar,
          width_ar: values.width_ar,
          application_ar: values.application_ar,
          overallHeight_ar: values.overallHeight_ar,
          bearingClearance_ar: values.bearingClearance_ar,
          axialClearance_ar: values.axialClearance_ar,
          rollerDiameter_ar: values.rollerDiameter_ar,
          pitchDiameter_ar: values.pitchDiameter_ar,
          contactAngle_ar: values.contactAngle_ar,
          runout_ar: values.runout_ar,
          weight_ar: values.weight_ar,
          overallLength_ar: values.overallLength_ar,
          boltHoleSpacing_ar: values.boltHoleSpacing_ar,
          boltHoleDiameter_ar: values.boltHoleDiameter_ar,
          shaftDiameter_ar: values.shaftDiameter_ar,
          mountingHeight_ar: values.mountingHeight_ar,
          distanceFromBaseToCenter_ar: values.distanceFromBaseToCenter_ar,
          threadQuality_ar: values.threadQuality_ar,
          surfaceFinish_ar: values.surfaceFinish_ar,
          antiCorrosionProperties_ar: values.antiCorrosionProperties_ar,
          threadLocking_ar: values.threadLocking_ar,
          compatibilityAndFitment_ar: values.compatibilityAndFitment_ar,
          testingAndQualityControl_ar: values.testingAndQualityControl_ar,
          precisionRating_ar: values.precisionRating_ar,
          materialComposition_ar: values.materialComposition_ar,
          manufacturingStandards_ar: values.manufacturingStandards_ar,
          loadCapacity_ar: values.loadCapacity_ar,
          sealingAndLubrication_ar: values.sealingAndLubrication_ar,
          toleranceAndFit_ar: values.toleranceAndFit_ar,
          durabilityAndLifespan_ar: values.durabilityAndLifespan_ar,
          speedRpm_ar: values.speedRpm_ar,
          noiseAndVibration_ar: values.noiseAndVibration_ar,
          packagingType_ar: values.packagingType_ar,
          marking_ar: values.marking_ar,

          outerDiameterBoolean: showOuterDiameter,
          innerDiameterBoolean: showInnerDiameter,
          widthBoolean: showWidth,
          applicationBoolean: showApplication,
          overallHeightBoolean: showOverallHeight,
          bearingClearanceBoolean: showBearingClearance,
          axialClearanceBoolean: showAxialClearance,
          rollerDiameterBoolean: showRollerDiameter,
          pitchDiameterBoolean: showPitchDiameter,
          contactAngleBoolean: showContactAngle,
          runoutBoolean: showRunout,
          weightBoolean: showWeight,
          overallLengthBoolean: showOverallLength,
          boltHoleSpacingBoolean: showBoltHoleSpacing,
          boltHoleDiameterBoolean: showBoltHoleDiameter,
          shaftDiameterBoolean: showShaftDiameter,
          mountingHeightBoolean: showMountingHeight,
          distanceFromBaseToCenterBoolean: showDistanceFromBaseToCenter,
          threadQualityBoolean: showThreadQuality,
          surfaceFinishBoolean: showSurfaceFinish,
          antiCorrosionPropertiesBoolean: showAntiCorrosionProperties,
          threadLockingBoolean: showThreadLocking,
          compatibilityAndFitmentBoolean: showCompatibilityAndFitment,
          testingAndQualityControlBoolean: showTestingAndQualityControl,
          precisionRatingBoolean: showPrecisionRating,
          materialCompositionBoolean: showMaterialComposition,
          manufacturingStandardsBoolean: showManufacturingStandards,
          loadCapacityBoolean: showLoadCapacity,
          sealingAndLubricationBoolean: showSealingAndLubrication,
          toleranceAndFitBoolean: showToleranceAndFit,
          durabilityAndLifespanBoolean: showDurabilityAndLifespan,
          speedRpmBoolean: showSpeedRpm,
          noiseAndVibrationBoolean: showNoiseAndVibration,
          packagingTypeBoolean: showPackagingType,
          markingBoolean: showMarking,
        };
        console.log(body, "body add product ...............");

        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const res = await updateProducts({
            id: id,
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            navigate("/manage-products")
            showToast("Product Updated")
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        const variationsWithNumbers = variations.map((variation) => ({
          ...variation,
          totalQuantity: Number(variation.totalQuantity),
          maxPurchaseQuantity: Number(variation.maxPurchaseQuantity),
          minPurchaseQuantity: Number(variation.minPurchaseQuantity),
          price: variation.price ? Number(String(variation.price).replace(/,/g, '')) : 0,
          size: variation.size,
        }));
        const body = {
          name: values.name,
          description: values.description,
          images: myImages,
          categoryId: selectedCategory,
          subCategoryId: selectedSubCategory,
          variations: variationsWithNumbers,
          dataInArabic: arabic,

          outerDiameter: values.outerDiameter,
          innerDiameter: values.innerDiameter,
          width: values.width,
          application: values.application,
          overallHeight: values.overallHeight,
          bearingClearance: values.bearingClearance,
          axialClearance: values.axialClearance,
          rollerDiameter: values.rollerDiameter,
          pitchDiameter: values.pitchDiameter,
          contactAngle: values.contactAngle,
          runout: values.runout,
          weight: values.weight,
          overallLength: values.overallLength,
          boltHoleSpacing: values.boltHoleSpacing,
          boltHoleDiameter: values.boltHoleDiameter,
          shaftDiameter: values.shaftDiameter,
          mountingHeight: values.mountingHeight,
          distanceFromBaseToCenter: values.distanceFromBaseToCenter,
          threadQuality: values.threadQuality,
          surfaceFinish: values.surfaceFinish,
          antiCorrosionProperties: values.antiCorrosionProperties,
          threadLocking: values.threadLocking,
          compatibilityAndFitment: values.compatibilityAndFitment,
          testingAndQualityControl: values.testingAndQualityControl,
          precisionRating: values.precisionRating,
          materialComposition: values.materialComposition,
          manufacturingStandards: values.manufacturingStandards,
          loadCapacity: values.loadCapacity,
          sealingAndLubrication: values.sealingAndLubrication,
          toleranceAndFit: values.toleranceAndFit,
          durabilityAndLifespan: values.durabilityAndLifespan,
          speedRpm: values.speedRpm,
          noiseAndVibration: values.noiseAndVibration,
          packagingType: values.packagingType,
          marking: values.marking,

          outerDiameter_ar: values.outerDiameter_ar,
          innerDiameter_ar: values.innerDiameter_ar,
          width_ar: values.width_ar,
          application_ar: values.application_ar,
          overallHeight_ar: values.overallHeight_ar,
          bearingClearance_ar: values.bearingClearance_ar,
          axialClearance_ar: values.axialClearance_ar,
          rollerDiameter_ar: values.rollerDiameter_ar,
          pitchDiameter_ar: values.pitchDiameter_ar,
          contactAngle_ar: values.contactAngle_ar,
          runout_ar: values.runout_ar,
          weight_ar: values.weight_ar,
          overallLength_ar: values.overallLength_ar,
          boltHoleSpacing_ar: values.boltHoleSpacing_ar,
          boltHoleDiameter_ar: values.boltHoleDiameter_ar,
          shaftDiameter_ar: values.shaftDiameter_ar,
          mountingHeight_ar: values.mountingHeight_ar,
          distanceFromBaseToCenter_ar: values.distanceFromBaseToCenter_ar,
          threadQuality_ar: values.threadQuality_ar,
          surfaceFinish_ar: values.surfaceFinish_ar,
          antiCorrosionProperties_ar: values.antiCorrosionProperties_ar,
          threadLocking_ar: values.threadLocking_ar,
          compatibilityAndFitment_ar: values.compatibilityAndFitment_ar,
          testingAndQualityControl_ar: values.testingAndQualityControl_ar,
          precisionRating_ar: values.precisionRating_ar,
          materialComposition_ar: values.materialComposition_ar,
          manufacturingStandards_ar: values.manufacturingStandards_ar,
          loadCapacity_ar: values.loadCapacity_ar,
          sealingAndLubrication_ar: values.sealingAndLubrication_ar,
          toleranceAndFit_ar: values.toleranceAndFit_ar,
          durabilityAndLifespan_ar: values.durabilityAndLifespan_ar,
          speedRpm_ar: values.speedRpm_ar,
          noiseAndVibration_ar: values.noiseAndVibration_ar,
          packagingType_ar: values.packagingType_ar,
          marking_ar: values.marking_ar,

          outerDiameterBoolean: showOuterDiameter,
          innerDiameterBoolean: showInnerDiameter,
          widthBoolean: showWidth,
          applicationBoolean: showApplication,
          overallHeightBoolean: showOverallHeight,
          bearingClearanceBoolean: showBearingClearance,
          axialClearanceBoolean: showAxialClearance,
          rollerDiameterBoolean: showRollerDiameter,
          pitchDiameterBoolean: showPitchDiameter,
          contactAngleBoolean: showContactAngle,
          runoutBoolean: showRunout,
          weightBoolean: showWeight,
          overallLengthBoolean: showOverallLength,
          boltHoleSpacingBoolean: showBoltHoleSpacing,
          boltHoleDiameterBoolean: showBoltHoleDiameter,
          shaftDiameterBoolean: showShaftDiameter,
          mountingHeightBoolean: showMountingHeight,
          distanceFromBaseToCenterBoolean: showDistanceFromBaseToCenter,
          threadQualityBoolean: showThreadQuality,
          surfaceFinishBoolean: showSurfaceFinish,
          antiCorrosionPropertiesBoolean: showAntiCorrosionProperties,
          threadLockingBoolean: showThreadLocking,
          compatibilityAndFitmentBoolean: showCompatibilityAndFitment,
          testingAndQualityControlBoolean: showTestingAndQualityControl,
          precisionRatingBoolean: showPrecisionRating,
          materialCompositionBoolean: showMaterialComposition,
          manufacturingStandardsBoolean: showManufacturingStandards,
          loadCapacityBoolean: showLoadCapacity,
          sealingAndLubricationBoolean: showSealingAndLubrication,
          toleranceAndFitBoolean: showToleranceAndFit,
          durabilityAndLifespanBoolean: showDurabilityAndLifespan,
          speedRpmBoolean: showSpeedRpm,
          noiseAndVibrationBoolean: showNoiseAndVibration,
          packagingTypeBoolean: showPackagingType,
          markingBoolean: showMarking,
        };

        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const res: any = await addProduct({
            body: encryptedBody,
          });
          if (res?.data?.statusCode === 200) {
            showToast("Product Added")
            navigate("/manage-products")
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });
  function formatNumberWithCommas(value: string | number) {
    const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
    const [integer, decimal] = valueStr.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let result= decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
    console.log('result123: ', result);
    return result;
}

  
  
  useEffect(() => {
    getCategory();
    getAllVarients();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      getAllSubCategory(selectedCategory);
    }
  }, [selectedCategory]);

  console.log(formik2.values.productCost, " formik123");



  useEffect(() => {
    if (id) {
      fetchDetails(id);
    }
  }, []);
  console.log(arabic, "arabic");

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard" >
          <h1>{id ? "Edit Product Specifications" : "Add Product Specifications"}</h1>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ textAlign: "center", paddingRight: "15px", paddingLeft: "15px", borderRadius: "5px", marginRight: "10px", paddingTop: "3px", boxShadow: "1x 1px 1px" }}>
              <h4 style={{ margin: "0px", marginBottom: "-2px" }}>{id ? "Edit Details in Arabic" : "Add Details in Arabic"}</h4>
              <Switch size="medium" {...label} checked={arabic} onChange={() => setArabic((prevArabic) => !prevArabic)} />
            </div>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-products");
              }}
            >
              Back
            </Button>

          </div>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <ul className="prdct_images">
                <div>
                  {myImages?.length > 20 ? null : (
                    <label htmlFor="icon-button-files" style={{ width: "300px" }}>
                      <div className="uploader" >
                        <input
                          style={{ display: "none" }}
                          id="icon-button-files"
                          type="file"
                          onChange={handleFileUpload}
                          accept=".png, .jpg, .jpeg, .mp4, .mkv"
                          multiple
                        />
                        <AddIcon />
                        <h3>Add Product image/video</h3>
                        <h6>Upload upto 10 Mb</h6>
                      </div>
                    </label>
                  )}
                  {/* <div style={{ marginTop: "10px", paddingInline: "30px", color: 'gray' }}>Recommended size is 1280 x 720</div> */}
                </div>
                {myImages?.length ? (
                  <>
                    {myImages?.slice(0, viewAll ? myImages.length : 5).map((item: any, i: any) => {
                      return (
                        <li key={i}>
                          <ClearIcon
                            className="cross"
                            onClick={(e) => handleCrossClick(e, i)}
                          />

                          {item.type === 'image' ? (
                            <figure>
                              <img src={item.src} alt="upload" />
                            </figure>
                          ) : (
                            <figure>
                              <video controls preload="metadata" autoPlay={false} width="100%" height="100%" style={{ objectFit: "cover", borderRadius: "20px" }}>
                                <source src={item.src} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </figure>
                          )}
                        </li>
                      );
                    })}

                    {myImages?.length > 5 && (
                      <Button
                        className="btn btn_primary"
                        onClick={() => {
                          // setView(!view)
                          setViewAll(!viewAll)
                        }}
                      >
                        {buttonText}
                      </Button>
                    )}
                  </>
                ) : null}
              </ul>

              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">
                      {arabic ? "Product category (Arabic)" : "Product category (English)"}
                    </Typography>{" "}
                    <Select
                      className="select_div "
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCategory}
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {category?.map((item, index) => (
                        <MenuItem value={item?._id}>{!arabic ? item?.name : item?.name_ar}</MenuItem>
                      ))}
                    </Select>
                    {categoryError && selectedCategory == "" ? (
                      <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                        Category is required
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">
                      {arabic ? "Product Sub-category (Arabic)" : "Product Sub-category (English)"}
                    </Typography>{" "}
                    <Select
                      className="select_div "
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedSubCategory}
                      displayEmpty
                      disabled={subCategory.length === 0 ? true : false}
                      // label="Age"
                      onChange={handleSubCategory}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      {subCategory?.map((item, index) => (
                        <MenuItem value={item?._id}>{!arabic ? item?.name : item?.name_ar}</MenuItem>
                      ))}
                    </Select>
                    {categoryError && selectedSubCategory == "" ? (
                      <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                        Sub-category is required
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                {!arabic ? (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">Product Name/Code(English)</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Product Name"
                      value={formik.values.name}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.name && formik.errors.name}

                      onBlur={formik.handleBlur}

                    />
                  </Grid>
                ) : (
                  <Grid item lg={4} md={4} sm={6} xs={12} >
                    <Typography className="custom_label">Product Name/Code(Arabic)</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name_ar"
                      variant="outlined"
                      fullWidth
                      placeholder="Product Name"
                      value={formik.values.name_ar}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.name_ar && formik.errors.name_ar}
                      onBlur={formik.handleBlur}

                    />
                  </Grid>
                )}

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Outer Diameter (OD)
                    <Checkbox
                      checked={showOuterDiameter}
                      onChange={() => setShowOuterDiameter(!showOuterDiameter)}
                    />
                  </Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Outer Diameter"
                    name={!arabic ? "outerDiameter" : "outerDiameter_ar"}
                    value={!arabic ? formik.values.outerDiameter : formik.values.outerDiameter_ar}
                    helperText={!arabic ? (formik.touched.outerDiameter && formik.errors.outerDiameter) : (formik.touched.outerDiameter_ar && formik.errors.outerDiameter_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Inner Diameter (ID)
                    <Checkbox
                      checked={showInnerDiameter}
                      onChange={() => setShowInnerDiameter(!showInnerDiameter)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    name={!arabic ? "innerDiameter" : "innerDiameter_ar"}
                    placeholder="Inner Diameter"
                    value={!arabic ? formik.values.innerDiameter : formik.values.innerDiameter_ar}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    helperText={!arabic ? (formik.touched.innerDiameter && formik.errors.innerDiameter) : (formik.touched.innerDiameter_ar && formik.errors.innerDiameter_ar)}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Width (W)
                    <Checkbox
                      checked={showWidth}
                      onChange={() => setShowWidth(!showWidth)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="width"
                    name={!arabic ? "width" : "width_ar"}
                    value={!arabic ? formik.values.width : formik.values.width_ar}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    helperText={!arabic ? (formik.touched.width && formik.errors.width) : (formik.touched.width_ar && formik.errors.width_ar)}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Application
                    <Checkbox
                      checked={showApplication}
                      onChange={() => setShowApplication((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name={!arabic ? "application" : "application_ar"}
                    variant="outlined"
                    fullWidth
                    placeholder="Application"
                    value={!arabic ? formik.values.application : formik.values.application_ar}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    helperText={!arabic ? (formik.touched.application && formik.errors.application) : (formik.touched.application_ar && formik.errors.application_ar)}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Overall Height
                    <Checkbox
                      checked={showOverallHeight}
                      onChange={() => setShowOverallHeight((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name={!arabic ? "overallHeight" : "overallHeight_ar"}
                    variant="outlined"
                    fullWidth
                    placeholder="Overall Height"
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    value={!arabic ? formik.values.overallHeight : formik.values.overallHeight_ar}
                    helperText={!arabic ? (formik.touched.overallHeight && formik.errors.overallHeight) : (formik.touched.overallHeight_ar && formik.errors.overallHeight_ar)}
                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Bearing Clearance
                    <Checkbox
                      checked={showBearingClearance}
                      onChange={() => setShowBearingClearance((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Bearing Clearance"
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    name={!arabic ? "bearingClearance" : "bearingClearance_ar"}
                    value={!arabic ? formik.values.bearingClearance : formik.values.bearingClearance_ar}
                    helperText={!arabic ? (formik.touched.bearingClearance && formik.errors.bearingClearance) : (formik.touched.bearingClearance_ar && formik.errors.bearingClearance_ar)}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Axial Clearance
                    <Checkbox
                      checked={showAxialClearance}
                      onChange={() => setShowAxialClearance((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Axial Clearance"
                    name={!arabic ? "axialClearance" : "axialClearance_ar"}
                    value={!arabic ? formik.values.axialClearance : formik.values.axialClearance_ar}
                    helperText={!arabic ? (formik.touched.axialClearance && formik.errors.axialClearance) : (formik.touched.axialClearance_ar && formik.errors.axialClearance_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Ball or Roller Diameter
                    <Checkbox
                      checked={showRollerDiameter}
                      onChange={() => setShowRollerDiameter((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Ball or Roller Diameter"
                    name={!arabic ? "rollerDiameter" : "rollerDiameter_ar"}
                    value={!arabic ? formik.values.rollerDiameter : formik.values.rollerDiameter_ar}
                    helperText={!arabic ? (formik.touched.rollerDiameter && formik.errors.rollerDiameter) : (formik.touched.rollerDiameter_ar && formik.errors.rollerDiameter_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Pitch Diameter
                    <Checkbox
                      checked={showPitchDiameter}
                      onChange={() => setShowPitchDiameter((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    fullWidth
                    placeholder="Pitch Diameter"
                    variant="outlined"
                    name={!arabic ? "pitchDiameter" : "pitchDiameter_ar"}
                    value={!arabic ? formik.values.pitchDiameter : formik.values.pitchDiameter_ar}
                    helperText={!arabic ? (formik.touched.pitchDiameter && formik.errors.pitchDiameter) : (formik.touched.pitchDiameter_ar && formik.errors.pitchDiameter_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Contact Angle
                    <Checkbox
                      checked={showContactAngle}
                      onChange={() => setShowContactAngle((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Contact Angle"
                    name={!arabic ? "contactAngle" : "contactAngle_ar"}
                    value={!arabic ? formik.values.contactAngle : formik.values.contactAngle_ar}
                    helperText={!arabic ? (formik.touched.contactAngle && formik.errors.contactAngle)
                      : (formik.touched.contactAngle_ar && formik.errors.contactAngle_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Runout
                    <Checkbox
                      checked={showRunout}
                      onChange={() => setShowRunout((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Runout"
                    name={!arabic ? "runout" : "runout_ar"}
                    value={!arabic ? formik.values.runout : formik.values.runout_ar}
                    helperText={!arabic ? (formik.touched.runout && formik.errors.runout)
                      : (formik.touched.runout_ar && formik.errors.runout_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Weight
                    <Checkbox
                      checked={showWeight}
                      onChange={() => setShowWeight((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="weight"
                    name={!arabic ? "weight" : "weight_ar"}
                    value={!arabic ? formik.values.weight : formik.values.weight_ar}
                    helperText={!arabic ? (formik.touched.weight && formik.errors.weight)
                      : (formik.touched.weight_ar && formik.errors.weight_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Overall Length  <Checkbox
                    checked={showOverallLength}
                    onChange={() => setShowOverallLength((prev) => !prev)}
                  /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Overall Length"
                    name={!arabic ? "overallLength" : "overallLength_ar"}
                    value={!arabic ? formik.values.overallLength : formik.values.overallLength_ar}
                    helperText={!arabic ? (formik.touched.overallLength && formik.errors.overallLength)
                      : (formik.touched.overallLength_ar && formik.errors.overallLength_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Bolt Hole Spacing  <Checkbox
                    checked={showBoltHoleSpacing}
                    onChange={() => setShowBoltHoleSpacing((prev) => !prev)}
                  /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Bolt Hole Spacing"
                    name={!arabic ? "boltHoleSpacing" : "boltHoleSpacing_ar"}
                    value={!arabic ? formik.values.boltHoleSpacing : formik.values.boltHoleSpacing_ar}
                    helperText={!arabic ? (formik.touched.boltHoleSpacing && formik.errors.boltHoleSpacing)
                      : (formik.touched.boltHoleSpacing_ar && formik.errors.boltHoleSpacing_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                  // onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Bolt Hole Diameter
                    <Checkbox
                      checked={showBoltHoleDiameter}
                      onChange={() => setShowBoltHoleDiameter((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    fullWidth
                    placeholder="Bolt Hole Diameter"
                    name={!arabic ? "boltHoleDiameter" : "boltHoleDiameter_ar"}
                    value={!arabic ? formik.values.boltHoleDiameter : formik.values.boltHoleDiameter_ar}
                    helperText={!arabic ? (formik.touched.boltHoleDiameter && formik.errors.boltHoleDiameter)
                      : (formik.touched.boltHoleDiameter_ar && formik.errors.boltHoleDiameter_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Shaft Diameter (ID)
                    <Checkbox
                      checked={showShaftDiameter}
                      onChange={() => setShowShaftDiameter((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Shaft Diameter"
                    name={!arabic ? "shaftDiameter" : "shaftDiameter_ar"}
                    value={!arabic ? formik.values.shaftDiameter : formik.values.shaftDiameter_ar}
                    helperText={!arabic ? (formik.touched.shaftDiameter && formik.errors.shaftDiameter)
                      : (formik.touched.shaftDiameter_ar && formik.errors.shaftDiameter_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Mounting Height (H)
                    <Checkbox
                      checked={showMountingHeight}
                      onChange={() => setShowMountingHeight((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Mounting Height"
                    name={!arabic ? "mountingHeight" : "mountingHeight_ar"}
                    value={!arabic ? formik.values.mountingHeight : formik.values.mountingHeight_ar}
                    helperText={!arabic ? (formik.touched.mountingHeight && formik.errors.mountingHeight)
                      : (formik.touched.mountingHeight_ar && formik.errors.mountingHeight_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Distance from Base to Center (BC)
                    <Checkbox
                      checked={showDistanceFromBaseToCenter}
                      onChange={() => setShowDistanceFromBaseToCenter((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Distance from Base to Center"
                    name={!arabic ? "distanceFromBaseToCenter" : "distanceFromBaseToCenter_ar"}
                    value={!arabic ? formik.values.distanceFromBaseToCenter : formik.values.distanceFromBaseToCenter_ar}
                    helperText={!arabic ? (formik.touched.distanceFromBaseToCenter && formik.errors.distanceFromBaseToCenter)
                      : (formik.touched.distanceFromBaseToCenter_ar && formik.errors.distanceFromBaseToCenter_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Thread Quality
                    <Checkbox
                      checked={showThreadQuality}
                      onChange={() => setShowThreadQuality((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Thread Quality"
                    name={!arabic ? "threadQuality" : "threadQuality_ar"}
                    value={!arabic ? formik.values.threadQuality : formik.values.threadQuality_ar}
                    helperText={!arabic ? (formik.touched.threadQuality && formik.errors.threadQuality)
                      : (formik.touched.threadQuality_ar && formik.errors.threadQuality_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Surface Finish
                    <Checkbox
                      checked={showSurfaceFinish}
                      onChange={() => setShowSurfaceFinish((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Surface Finish"
                    name={!arabic ? "surfaceFinish" : "surfaceFinish_ar"}
                    value={!arabic ? formik.values.surfaceFinish : formik.values.surfaceFinish_ar}
                    helperText={!arabic ? (formik.touched.surfaceFinish && formik.errors.surfaceFinish)
                      : (formik.touched.surfaceFinish_ar && formik.errors.surfaceFinish_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Anti-Corrosion Properties
                    <Checkbox
                      checked={showAntiCorrosionProperties}
                      onChange={() => setShowAntiCorrosionProperties((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Anti-Corrosion Properties"
                    name={!arabic ? "antiCorrosionProperties" : "antiCorrosionProperties_ar"}
                    value={!arabic ? formik.values.antiCorrosionProperties : formik.values.antiCorrosionProperties_ar}
                    helperText={!arabic ? (formik.touched.antiCorrosionProperties && formik.errors.antiCorrosionProperties)
                      : (formik.touched.antiCorrosionProperties_ar && formik.errors.antiCorrosionProperties_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Thread Locking
                    <Checkbox
                      checked={showThreadLocking}
                      onChange={() => setShowThreadLocking((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Thread Locking"
                    name={!arabic ? "threadLocking" : "threadLocking_ar"}
                    value={!arabic ? formik.values.threadLocking : formik.values.threadLocking_ar}
                    helperText={!arabic ? (formik.touched.threadLocking && formik.errors.threadLocking)
                      : (formik.touched.threadLocking_ar && formik.errors.threadLocking_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Compatibility and Fitment
                    <Checkbox
                      checked={showCompatibilityAndFitment}
                      onChange={() => setShowCompatibilityAndFitment((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Compatibility and Fitment"
                    name={!arabic ? "compatibilityAndFitment" : "compatibilityAndFitment_ar"}
                    value={!arabic ? formik.values.compatibilityAndFitment : formik.values.compatibilityAndFitment_ar}
                    helperText={!arabic ? (formik.touched.compatibilityAndFitment && formik.errors.compatibilityAndFitment)
                      : (formik.touched.compatibilityAndFitment_ar && formik.errors.compatibilityAndFitment_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Testing and Quality Control
                    <Checkbox
                      checked={showTestingAndQualityControl}
                      onChange={() => setShowTestingAndQualityControl((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Testing and Quality Control"
                    name={!arabic ? "testingAndQualityControl" : "testingAndQualityControl_ar"}
                    value={!arabic ? formik.values.testingAndQualityControl : formik.values.testingAndQualityControl_ar}
                    helperText={!arabic ? (formik.touched.testingAndQualityControl && formik.errors.testingAndQualityControl)
                      : (formik.touched.testingAndQualityControl_ar && formik.errors.testingAndQualityControl_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Precision Rating
                    <Checkbox
                      checked={showPrecisionRating}
                      onChange={() => setShowPrecisionRating((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Precision Rating"
                    name={!arabic ? "precisionRating" : "precisionRating_ar"}
                    value={!arabic ? formik.values.precisionRating : formik.values.precisionRating_ar}
                    helperText={!arabic ? (formik.touched.precisionRating && formik.errors.precisionRating)
                      : (formik.touched.precisionRating_ar && formik.errors.precisionRating_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Material Composition
                    <Checkbox
                      checked={showMaterialComposition}
                      onChange={() => setShowMaterialComposition((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Material Composition"
                    name={!arabic ? "materialComposition" : "materialComposition_ar"}
                    value={!arabic ? formik.values.materialComposition : formik.values.materialComposition_ar}
                    helperText={!arabic ? (formik.touched.materialComposition && formik.errors.materialComposition)
                      : (formik.touched.materialComposition_ar && formik.errors.materialComposition_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Manufacturing Standards
                    <Checkbox
                      checked={showManufacturingStandards}
                      onChange={() => setShowManufacturingStandards((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Manufacturing Standards"
                    name={!arabic ? "manufacturingStandards" : "manufacturingStandards_ar"}
                    value={!arabic ? formik.values.manufacturingStandards : formik.values.manufacturingStandards_ar}
                    helperText={!arabic ? (formik.touched.manufacturingStandards && formik.errors.manufacturingStandards)
                      : (formik.touched.manufacturingStandards_ar && formik.errors.manufacturingStandards_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Load Capacity
                    <Checkbox
                      checked={showLoadCapacity}
                      onChange={() => setShowLoadCapacity((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Load Capacity"
                    name={!arabic ? "loadCapacity" : "loadCapacity_ar"}
                    value={!arabic ? formik.values.loadCapacity : formik.values.loadCapacity_ar}
                    helperText={!arabic ? (formik.touched.loadCapacity && formik.errors.loadCapacity)
                      : (formik.touched.loadCapacity_ar && formik.errors.loadCapacity_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Sealing and Lubrication
                    <Checkbox
                      checked={showSealingAndLubrication}
                      onChange={() => setShowSealingAndLubrication((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Sealing and Lubrication"
                    name={!arabic ? "sealingAndLubrication" : "sealingAndLubrication_ar"}
                    value={!arabic ? formik.values.sealingAndLubrication : formik.values.sealingAndLubrication_ar}
                    helperText={!arabic ? (formik.touched.sealingAndLubrication && formik.errors.sealingAndLubrication)
                      : (formik.touched.sealingAndLubrication_ar && formik.errors.sealingAndLubrication_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Tolerance and Fit
                    <Checkbox
                      checked={showToleranceAndFit}
                      onChange={() => setShowToleranceAndFit((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Tolerance and Fit"
                    name={!arabic ? "toleranceAndFit" : "toleranceAndFit_ar"}
                    value={!arabic ? formik.values.toleranceAndFit : formik.values.toleranceAndFit_ar}
                    helperText={!arabic ? (formik.touched.toleranceAndFit && formik.errors.toleranceAndFit)
                      : (formik.touched.toleranceAndFit_ar && formik.errors.toleranceAndFit_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Durability and Lifespan
                    <Checkbox
                      checked={showDurabilityAndLifespan}
                      onChange={() => setShowDurabilityAndLifespan((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Durability and Lifespan"
                    name={!arabic ? "durabilityAndLifespan" : "durabilityAndLifespan_ar"}
                    value={!arabic ? formik.values.durabilityAndLifespan : formik.values.durabilityAndLifespan_ar}
                    helperText={!arabic ? (formik.touched.durabilityAndLifespan && formik.errors.durabilityAndLifespan)
                      : (formik.touched.durabilityAndLifespan_ar && formik.errors.durabilityAndLifespan_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Speed (RPM)
                    <Checkbox
                      checked={showSpeedRpm}
                      onChange={() => setShowSpeedRpm((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Speed (RPM)"
                    name={!arabic ? "speedRpm" : "speedRpm_ar"}
                    value={!arabic ? formik.values.speedRpm : formik.values.speedRpm_ar}
                    helperText={!arabic ? (formik.touched.speedRpm && formik.errors.speedRpm)
                      : (formik.touched.speedRpm_ar && formik.errors.speedRpm_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}

                    onBlur={formik.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Noise and vibration
                    <Checkbox
                      checked={showNoiseAndVibration}
                      onChange={() => setShowNoiseAndVibration((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Noise and vibration"
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    name={!arabic ? "noiseAndVibration" : "noiseAndVibration_ar"}
                    value={!arabic ? formik.values.noiseAndVibration : formik.values.noiseAndVibration_ar}
                    helperText={!arabic ? (formik.touched.noiseAndVibration && formik.errors.noiseAndVibration)
                      : (formik.touched.noiseAndVibration_ar && formik.errors.noiseAndVibration_ar)}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Packaging Type
                    <Checkbox
                      checked={showPackagingType}
                      onChange={() => setShowPackagingType((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Packaging Type"
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    name={!arabic ? "packagingType" : "packagingType_ar"}
                    value={!arabic ? formik.values.packagingType : formik.values.packagingType_ar}
                    helperText={!arabic ? (formik.touched.packagingType && formik.errors.packagingType)
                      : (formik.touched.packagingType_ar && formik.errors.packagingType_ar)}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Marking
                    <Checkbox
                      checked={showMarking}
                      onChange={() => setShowMarking((prev) => !prev)}
                    /></Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    placeholder="Marking"
                    name={!arabic ? "marking" : "marking_ar"}
                    value={!arabic ? formik.values.marking : formik.values.marking_ar}
                    helperText={!arabic ? (formik.touched.marking && formik.errors.marking)
                      : (formik.touched.marking_ar && formik.errors.marking_ar)}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginBlock: "20px" }}>
                <Box className="heading" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography component="h5">Add Variations</Typography>
                  {variationList.length !== 0 && <Button className={'btn btn_primary'} onClick={() => setDisplayVariant(!displayVariant)}>{
                    displayVariant ? "close" : "Add more"}</Button>}
                </Box>
              </Grid>

              {/*  khali fields */}
              {displayVariant && <Grid container spacing={2}>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Product Cost
                  </Typography>
                  <TextField
                    hiddenLabel
                    name="productCost"
                    variant="outlined"
                    fullWidth
                    value={formik2.values.productCost}
                    placeholder="Product Cost"
                    onChange={(val) => {
                      let { value } = val.target;
                      console.log('value123: ', value);
                      const numericValue = value.replace(/,/g, '');
                      console.log('numericValue123: ', numericValue);
                      if (
                        isNumberPrice(numericValue) &&
                        numericValue.length <= 12
                      ) {
                        const formattedValue =
                          formatNumberWithCommas(numericValue);
                        console.log(formattedValue, "formattedValue123");

                        formik2.setFieldValue('productCost', formattedValue);
                      }
                    }}
                    onBlur={formik2.handleBlur}
                    className="text_field"
                    helperText={formik2.touched.productCost && formik2.errors.productCost}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Product Quantity
                  </Typography>
                  <TextField
                    hiddenLabel
                    name='productlimit'
                    variant="outlined"
                    fullWidth
                    value={formik2.values.productlimit}
                    placeholder="Product Quantity"
                    id="dob"
                    className="text_field"
                    onChange={(e) => {
                      if (isNumber(e.target.value)) {
                        formik2.handleChange(e)
                      }
                    }}
                    onBlur={formik2.handleBlur}
                    helperText={formik2.touched.productlimit && formik2.errors.productlimit}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Product min quantity limit for purchase
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    fullWidth
                    name='minlimit'
                    value={formik2.values.minlimit}

                    placeholder="Product min quantity limit for purchase"
                    className="text_field"
                    onChange={(e) => {
                      if (isNumber(e.target.value)) {
                        formik2.handleChange(e)
                      }
                    }}
                    onBlur={formik2.handleBlur}
                    helperText={formik2.touched.minlimit && (parseInt(formik2.values.minlimit) > parseInt(formik2.values.productlimit) ? "Min limit should be less than total quantity " : formik2.errors.minlimit)}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Product quantity Max-limit for purchase
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    value={formik2.values.maxlimit}
                    fullWidth
                    name='maxlimit'
                    helperText={formik2.touched.maxlimit && formik2.errors.maxlimit}

                    placeholder="Product max quantity limit for purchase"
                    className="text_field"
                    onChange={(e) => {
                      if (isNumber(e.target.value)) {
                        formik2.handleChange(e)
                      }
                    }}
                    onBlur={formik2.handleBlur}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Product Size
                  </Typography>
                  <TextField
                    hiddenLabel
                    name='size'
                    variant="outlined"
                    fullWidth
                    value={formik2.values.size}
                    placeholder="Product Size"
                    id="size"
                    className="text_field"
                    onChange={(e) =>
                      formik2.handleChange(e)
                    }
                    onBlur={formik2.handleBlur}
                    helperText={formik2.touched.size && formik2.errors.size}

                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Button
                    sx={{ marginTop: 2.6 }}
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={(e: { preventDefault: () => void; }) => {
                      e.preventDefault();
                      addVariations(formik2.values, 'addVariations');
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>}
              <Grid container spacing={2}>
                <Grid item xs={12} />
                {
                  variations?.length != 0 && variations.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">Product Cost</Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name={`productCostList`}
                          variant="outlined"
                          onChange={(val) => {
                            let { value } = val.target;
                            const numericValue = value.replace(/,/g, '');
                            if (
                              isNumberPrice(numericValue) &&
                              numericValue.length <= 12
                            ) {
                              const formattedValue =
                                formatNumberWithCommas(numericValue);
                              setVaritaionList((prev) => {
                                const temp = [...prev];
                                temp[index].price = formattedValue;
                                return temp;
                              })
                            }
                          }}
                          value={formatNumberWithCommas(String(variationList[index]?.price))}
                          fullWidth
                          placeholder="Product Cost"
                        // onChange={(e) => {
                        //   if (isNumber(e.target.value)) {
                        //     setVaritaionList((prev) => {
                        //       const temp = [...prev];
                        //       temp[index].price = e.target.value;
                        //       return temp;
                        //     })
                        //   }
                        // }}
                        />
                        {updateError && variationList[index]?.price == "" ? (
                          <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                            Product cost is required
                          </h6>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">Product Quantity</Typography>
                        <TextField
                          hiddenLabel
                          variant="outlined"
                          fullWidth
                          placeholder="Product Quantity"
                          id="dob"
                          name='totalQuantityList'
                          onChange={(e) => {
                            if (isNumber(e.target.value)) {
                              setVaritaionList((prev) => {
                                const temp = [...prev];
                                temp[index].totalQuantity = e.target.value;
                                return temp;
                              })
                            }
                          }}
                          value={variationList[index]?.totalQuantity}

                          className="text_field"
                        />
                        {updateError && variationList[index]?.totalQuantity === "" ? <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                          Total quantity field is required
                        </h6> : ""}

                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">Product quantity Min-limit for purchase</Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            if (isNumber(e.target.value)) {
                              setVaritaionList((prev) => {
                                const temp = [...prev];
                                temp[index].minPurchaseQuantity = e.target.value;
                                return temp;
                              })
                            }
                          }}
                          value={variationList[index]?.minPurchaseQuantity}
                          className="text_field"
                        />

                        {updateError && variationList[index]?.minPurchaseQuantity == "" ? <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                          Min quantity is required
                        </h6> : (parseInt(variationList[index]?.totalQuantity) < parseInt(variationList[index]?.minPurchaseQuantity) ? <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                          Min quantity should be less than total quantity
                        </h6> : (parseInt(variationList[index]?.maxPurchaseQuantity) < parseInt(variationList[index]?.minPurchaseQuantity) ? (
                          <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                            Min quantity should be less than max quantity
                          </h6>
                        ) : (
                          ""
                        )))}


                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">Product quantity Max-limit for purchase</Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            if (isNumber(e.target.value)) {
                              setVaritaionList((prev) => {
                                const temp = [...prev];
                                temp[index].maxPurchaseQuantity = e.target.value;
                                return temp;
                              })
                            }
                          }}
                          value={variationList[index]?.maxPurchaseQuantity}
                          className="text_field"
                        />
                        {updateError && variationList[index]?.maxPurchaseQuantity == "" ? <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                          Max quantity is required
                        </h6> : (parseInt(variationList[index]?.totalQuantity) < parseInt(variationList[index]?.maxPurchaseQuantity) ? <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                          Max quantity should be less than total quantity
                        </h6> : (parseInt(variationList[index]?.maxPurchaseQuantity) < parseInt(variationList[index]?.minPurchaseQuantity) ? (
                          <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                            Max quantity should be more than min quantity
                          </h6>
                        ) : (
                          ""
                        )))}
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">Product Size</Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => setVaritaionList((prev) => {
                            const temp = [...prev];
                            temp[index].size = e.target.value;
                            return temp;
                          })}
                          value={variationList[index]?.size}
                          className="tesxt_field"
                        >
                          {updateError && variationList[index]?.maxPurchaseQuantity === "" && (
                            <h6 className="err_msg" style={{ paddingBlock: "10px" }}>
                              Size is required
                            </h6>
                          )}
                        </TextField>

                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        {id ? (
                          <Button
                            sx={{ marginTop: 2.6 }}
                            size="large"
                            type="submit"
                            className="btn btn_primary"
                            onClick={(e: { preventDefault: () => void; }) => {
                              e.preventDefault();
                              if (variationList[index]?.price == "" ||
                                variationList[index]?.totalQuantity == "" ||
                                variationList[index]?.minPurchaseQuantity == "" ||
                                variationList[index]?.maxPurchaseQuantity == "" ||
                                parseInt(variationList[index]?.maxPurchaseQuantity) < parseInt(variationList[index]?.minPurchaseQuantity) ||
                                parseInt(variationList[index]?.maxPurchaseQuantity) > parseInt(variationList[index]?.totalQuantity) ||
                                parseInt(variationList[index]?.minPurchaseQuantity) > parseInt(variationList[index]?.totalQuantity)
                              ) {
                                setUpdateError(true);
                                return;
                              }

                              setVariations((prevData: any) => {
                                const temp = [...prevData];
                                temp[index] = variationList[index];
                                return temp;
                              })
                              showToast("Variant updated successfully")
                              setUpdateError(false);

                            }}
                          >
                            Save
                          </Button>
                        ) : (null)}

                        <WarnModal open={open} setOpen={setOpen} handleDelete={() => { handleDelete(index) }} name="variant" />
                        <Tooltip title="Delete">
                          <IconButton sx={{ marginTop: 3.2, marginLeft: 3.4, color: '#029be0' }} >
                            <DeleteIcon
                              onClick={(() => setOpen(!open))}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} />
                    </React.Fragment>
                  ))
                }

                {!arabic ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Product Description(English)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      name="description"
                      value={formik.values.description}
                      placeholder="Product Description"
                      className="text_field"
                      multiline
                      minRows={4}
                      maxRows={4}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Product Description(Arabic)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      name="description_ar"
                      value={formik.values.description_ar}
                      placeholder="Product Description"
                      className="text_field"
                      multiline
                      minRows={4}
                      maxRows={4}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.description_ar && formik.errors.description_ar}
                    />

                  </Grid>
                )}



              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary" onClick={() => {
                  if (formik2.isValid) {
                    console.log('FFFFF');
                    addVariations(formik2.values, 'save');
                  }
                  setCategoryError(true);
                }}>
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </div>
  );
};


export default AddProductForm;