import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  SelectChangeEvent,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import { useLazyGetCategoryByIdQuery, usePostCategoryMutation, useUpdateCategoryByIdMutation } from "../../services/categories";
import { CommonBody, TruckCategory } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import { UploadMedia } from "../../utils/mediaUpload";
import { generateEncryptedKeyBody } from "../../utils/crypto";

const AddCategories = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState<TruckCategory>();
  const [categoryDetails] = useLazyGetCategoryByIdQuery();
  const [addCategory, { isLoading }] = usePostCategoryMutation();
  const [updateCategory] = useUpdateCategoryByIdMutation();
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const location = useLocation();
  const { state } = location;


  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };
  const getCategoryDetail = async (id: string | undefined) => {
    try {
      const res = await categoryDetails({ id }).unwrap();
      if (res?.statusCode === 200) {
        setCategory(res?.data);
        setImage(res?.data?.image);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: category?.image || " ",
      name: category?.name || "",
      name_ar: category?.name_ar || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Category name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed")
        .matches(
          /^\S/,
          "Category name cannot start with a blank space"
        ),
      name_ar: Yup.string()
        .required("Category name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed")
        .matches(
          /^\S/,
          "Category name cannot start with a blank space"
        ),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let data = {
        name: values.name,
        name_ar: values.name_ar,
        image: image,
      };
      console.log(data, "category body");

      if (!image) {
        showError("Image is required")
        return;
      }

      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateCategory({ body: encryptedBody, id }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Category updated successfully!");
            navigate("/manage-categories", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addCategory(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Category created successfully!");
            navigate("/manage-categories", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  useEffect(() => {
    if (id) {
      getCategoryDetail(id);
    }
  }, []);



  return (
    <>
      <div>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Category" : "Add Category"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                            setFileName("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e)}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/pictures.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Category Name (English)<span className="asterisk">*</span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Category Name"
                      className="text_field"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Category Name (Arabic)<span className="asterisk">*</span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name_ar"
                      variant="outlined"
                      fullWidth
                      placeholder="Category Name"
                      className="text_field"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.name_ar}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.name_ar && formik.errors.name_ar}
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddCategories;
