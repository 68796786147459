import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Modal,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import { useDeleteAdminNotificationMutation, useDeleteAllAdminNotificationMutation, useLazyGetAdminNotificationQuery } from "../../services/notification";
import { showError, showToast } from "../../constants/toast";
import { Notification } from "../../types/General";
import { Pagination, WarnModal } from "../../components";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { useNavigate } from "react-router-dom";
import { handleDelete } from "../../utils/commonFunctions";
import { generateResponsiveStyle } from "../../utils/ModalStyle";

const RecievedNotifications = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [open1, setOpen1] = useState<boolean>(false);
  const [getAllNotification, { isLoading }] = useLazyGetAdminNotificationQuery();
  const [deleteNotification] = useDeleteAdminNotificationMutation();
  const [clearAll] = useDeleteAllAdminNotificationMutation();
  const [notData, setNotData] = useState<Notification[]>([]);
  const [selectedId, setSelectedId] = useState<string>("")
  const [page, setPage] = useState<number>(1)
  const [open, setOpen] = useState(false);
  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [totalCount, setTotalCount] = useState(0);
  let totalPages = Math.ceil(totalCount / 10);
  const style = generateResponsiveStyle();
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const fetchNotification = async () => {
    try {
      const res = await getAllNotification({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim()
      }).unwrap();
      if (res?.statusCode === 200) {
        setNotData(res?.data?.notification)
        setTotalCount(res?.data?.notificationCount || 0)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }
  const checkPermission = () => {
    const permission = UserData?.permissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Discount and Coupon Management"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const handleDeleteAll = async () => {
    try {
      const res = await clearAll({ clearAll: true }).unwrap();
      if (res?.statusCode === 200) {
        showToast("All notification cleared")
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }

  useEffect(() => {
    checkPermission();
  }, [UserData])


  useEffect(() => {
    fetchNotification();
  }, [page, debouncedSearchTerm, searchTerm]);

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Recieved Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary"
                onClick={() => setOpen1(true)}
              >
                Clear All
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Customer Name</TableCell>
                  <TableCell align="center">Customer Email</TableCell>
                  <TableCell align="center">Title</TableCell>
                  <TableCell align="center">Message</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {notData?.length ? (
                notData.map((row, i) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                      <TableCell align="center">{row?.user?.fullName}</TableCell>
                      <TableCell align="center">{row?.user?.email}</TableCell>
                      <TableCell align="center">{row?.title}</TableCell>
                      <TableCell align="center">{row?.message}</TableCell>
                      <TableCell align="center">
                        <Box className="table_actions">
                          {hidePermission?.isDelete || UserData?.role === 1 ? (
                            <Tooltip title="Delete">
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
                )
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No Notification Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        module={notData}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        name="notification"
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(deleteNotification, selectedId, fetchNotification)}
      />
      <Modal
        open={open1}
        onClose={() => setOpen1(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "20px", color: "#1c6975" }}
          >
            Are you sure you want to clear all the notifications ?
          </Typography>

          <div className="flexdiv">
            <Button
              className="yesBtn"
              sx={{ color: "#252525", border: "1px solid #252525" }}
              onClick={() => {
                setOpen1(false);
                handleDeleteAll();
                fetchNotification();
              }}
            >
              Yes
            </Button>

            <Button
              sx={{ color: "#252525", border: "1px solid #252525" }}
              onClick={() => setOpen1(false)}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>


   </div>
  );
};

export default RecievedNotifications;
