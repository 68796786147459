import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";


import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Box,
    Switch,
    Button,
    Grid,
    FormControl,
    Typography,
    Select,
    MenuItem,
    TextField,

} from "@mui/material";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import { useLazyGetInventoryQuery, useUpdateInventoryMutation } from "../../services/inventoryManagement";
import Loader from "../../constants/Loader";
import { showError, showToast } from "../../constants/toast";
import { Pagination } from "../../components";
import { CommonBody, InventoryVariant, ProductVariant } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";

// Define a type for form values
type FormValues = Record<string, string>;

const ManageVariants = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const label = { inputProps: { "aria-label": "Switch demo" } };
    const [getDetails, { isLoading }] = useLazyGetInventoryQuery();
    const [page, setPage] = useState<number>(1)
    const [productData, setProductData] = useState<InventoryVariant[]>([])
    const [totalCount, setTotalCount] = useState<number>(0);
    const [updateQuantity] = useUpdateInventoryMutation();
    const [formData, setFormData] = useState<Record<string, string>>({});
    let totalPages = Math.ceil(totalCount / 10);


    const fetchData = async (id: string | undefined) => {
        try {
            const res = await getDetails({
                id: id,
                query: debouncedSearchTerm.trim(),
                page: page,
                size: 10,
            }).unwrap();
            console.log(res, "ressss@@");

            if (res?.statusCode === 200) {
                setProductData(res?.data?.productVariant || [])
                setTotalCount(res?.data?.count)
            }
        } catch (error: any) {
            showError(error?.data?.message);
        }
    }


    const handleUpdateQuantity = async (variantId: string, newQuantity: string) => {
        try {
            const quantityValue = Number(newQuantity);

            if (quantityValue < 0) {
                showError("Quantity cannot be less than 0");
                return;
            }
            const data = {
                quantityLeft: Number(newQuantity),
                totalQuantity: Number(newQuantity)
            };
            const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
            const response = await updateQuantity({ id: variantId, body: encryptedBody }).unwrap();
            if (response?.statusCode === 200) {
                showToast(response?.message || "Quantity Updated Successfully");
                fetchData(id);
            }
            setProductData(prevProductData =>
                prevProductData.map(variant =>
                    variant._id === variantId ? { ...variant, quantityLeft: Number(newQuantity), totalQuantity: Number(newQuantity) } : variant
                )
            );
        } catch (error: any) {
            showError(error?.data?.message || "");
            console.log(error);
        }
    };

    function formatNumberWithCommas(value: string | number) {
        const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
        const [integer, decimal] = valueStr.split('.');
        const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let result= decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
        console.log('result123: ', result);
        return result;
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formDataEntries = Object.entries(formData);
        if (formDataEntries.length > 0) {
            const [variantId, newQuantity] = formDataEntries[0];
            await handleUpdateQuantity(variantId, newQuantity);
            setFormData({});
        }
    };

    const onPageChange = (newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [searchTerm, page, debouncedSearchTerm])

    return (
        <div>
            <Loader isLoad={isLoading} />
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Product variants</h1>
                    <Button
                        className="btn btn_primary"
                        onClick={() => {
                            navigate("/inventory-management");
                        }}
                    >
                        Back
                    </Button>
                </div>
                <Card className="cards">
                    <Box className="cards_header">
                        <SearchBar
                            searchTerm={searchTerm}
                            setDebouncedSearchTerm={setDebouncedSearchTerm}
                            value={searchTerm}
                            onCross={() => setSearchTerm("")}
                            onChange={(val: any) => {
                                if (isValidInput(val.target.value)) {
                                    setSearchTerm(val.target.value);
                                }
                            }
                            }
                        />
                    </Box>
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.no</TableCell>
                                    <TableCell align="center">Size</TableCell>
                                    <TableCell align="center">Price</TableCell>
                                    <TableCell align="center">Max purchase limit</TableCell>
                                    <TableCell align="center">Min purchase limit</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                productData?.length ? (
                                    productData.map((row, i) => (
                                        <TableBody key={row._id}>
                                            <TableRow>
                                                <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                                                <TableCell align="center">{row?.size || "-"}</TableCell>
                                                <TableCell align="center">AED {" "}{row?.price ? formatNumberWithCommas(String(row?.price)):""}</TableCell>
                                                <TableCell align="center">{row?.maxPurchaseQuantity}</TableCell>
                                                <TableCell align="center">{row?.minPurchaseQuantity}</TableCell>
                                                <TableCell align="center">
                                                    <Box className="update_inventry">
                                                        <form style={{ display: "flex", gap: "10px" }} onSubmit={handleSubmit}>
                                                            <TextField
                                                                hiddenLabel
                                                                type={"text"}
                                                                name={`${row._id}`}
                                                                variant="outlined"
                                                                fullWidth
                                                                value={formData[`${row._id}`] !== undefined ? formData[`${row._id}`] : row.quantityLeft}
                                                                onChange={(event) => setFormData({ [`${row._id}`]: event.target.value })}
                                                            />
                                                            <Button
                                                                className="btn btn_primary"
                                                                type="submit"
                                                            >Update
                                                            </Button>
                                                        </form>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))
                                ) : (
                                    <TableBody>
                                        <TableCell
                                            align="center"
                                            colSpan={10}
                                            sx={{ color: "#051140" }}
                                        >
                                            No data Found
                                        </TableCell>
                                    </TableBody>
                                )
                            }
                        </Table>
                    </TableContainer>
                </Card>
            </div >
            <Pagination
                module={productData}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
            />
        </div >
    );
};

export default ManageVariants;
