import {
    Box,
    IconButton,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    FormControl,
    Typography,
    Select,
    MenuItem,
    Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import { CommonBody, OrderData } from "../../types/General";
import { Pagination } from "../../components";
import { useUpdateStatusMutation } from "../../services/orders";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { showError, showToast } from "../../constants/toast";
import moment from "moment";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";

interface TabProps {
    Data: OrderData[];
    count: number;
    fetchOrderData: () => void;
    page: number;
    onPageChange: (newPage: number) => void;
}


const ONGOING: React.FC<TabProps> = ({ Data, count, fetchOrderData, page, onPageChange }) => {
    const navigate = useNavigate();

    const label = { inputProps: { "aria-label": "Switch demo" } };
    const [updateStatus] = useUpdateStatusMutation();
    let totalPages = Math.ceil(count / 10);
    const [selectedStatus, setSelectedStatus] = useState<number>(0);

    const UserData = useAuth();
    const [hidePermission, setHidePermission] = useState<
        Permissions | null | undefined
    >(null);


    const userPermissions = UserData?.permissions?.length
        ? (UserData?.permissions)
        : [];

    const checkPermission = () => {
        const permission = userPermissions;
        if (permission?.length) {
            let idx = -1;
            idx = permission?.findIndex(
                (ele: Permissions) => ele?.label === "Product Order Management"
            );
            if (idx > -1) {
                setHidePermission(permission[idx]);
            } else {
                navigate(-1);
            }
        }
    };

    function formatNumberWithCommas(value: string | number) {
        const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
        const [integer, decimal] = valueStr.split('.');
        const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
        return result;
      }

    //update user status api
    const updateOrderStatus = async (id: string, status: number) => {
        setSelectedStatus(status);
        const data = {
            status: status,
        };
        try {
            let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
            const res = await updateStatus({ id, body: encryptedBody }).unwrap();
            if (res?.statusCode === 200) {
                showToast("Order status updated successfully");
                fetchOrderData();
            }
        } catch (error: any) {
            showError(error?.message || "Error updating order status");
        }
    };

    useEffect(() => {
        fetchOrderData()
    }, [page])

    useEffect(() => {
        checkPermission();
    }, [UserData])

    return (
        <div>
            <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">S.No</TableCell>
                            <TableCell align="center">Date</TableCell>
                            <TableCell align="center">Order Id</TableCell>
                            <TableCell align="center">Customer Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Product Name</TableCell>
                            <TableCell align="center">Total cost</TableCell>
                            <TableCell align="center">Payment Received</TableCell>
                            <TableCell align="center">Balance Payment</TableCell>
                            <TableCell align="center">Product status</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        Data?.length ? (
                            Data?.map((row, i) => {
                                return (
                                    <TableBody>
                                        <TableRow >
                                            <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                                            <TableCell align="center">{moment(row?.createdAt).format("DD-MM-YYYY") || "-"}</TableCell>
                                            <TableCell align="center" > {row?.orderId || "-"}</TableCell>
                                            <TableCell align="center">{row?.user?.fullName ? (row?.user?.fullName) : (row?.user?.firstName + " " + row?.user?.lastName) || "-"}</TableCell>
                                            <TableCell align="center">{row?.address?.email || "-"}</TableCell>
                                            <TableCell align="center">{row?.productVariant?.name || "-"}</TableCell>
                                            <TableCell align="center">AED{" "}{row?.totalPriceOfProduct ? formatNumberWithCommas(String(row?.totalPriceOfProduct)) : "-"}</TableCell>
                                            <TableCell align="center">AED{" "}{row?.advanceAmountPaid ? formatNumberWithCommas(String(row?.advanceAmountPaid)) : "-"}</TableCell>
                                            <TableCell align="center">AED{" "}{row?.pendingAmountLeft ? formatNumberWithCommas(String(row?.pendingAmountLeft)) : "-"}</TableCell>
                                            <TableCell align="center">
                                                <FormControl fullWidth>
                                                    {hidePermission?.isEdit || UserData?.role === 1 ? (
                                                        <Select
                                                            className="select_div"
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={row?.status || 5}
                                                            onChange={(event) => updateOrderStatus(row?._id, (event.target.value as number))}
                                                        >
                                                            <MenuItem value={2} disabled >Accepted</MenuItem>
                                                            <MenuItem value={5} disabled={row?.status === 6}>Packed</MenuItem>
                                                            <MenuItem value={6}>Shipped</MenuItem>
                                                            <MenuItem value={7}>Reject</MenuItem>
                                                            <MenuItem value={3}>Delivered</MenuItem>
                                                        </Select>
                                                    ) : null}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <Box className="table_actions">
                                                    <Tooltip title="View">
                                                        <IconButton
                                                            onClick={() =>
                                                                navigate(`/product-order-management/details/${row?._id}`, { state: { type: "ONGOING" } })
                                                            }
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                );
                            })
                        ) : (
                            <TableBody>
                                <TableCell
                                    align="center"
                                    colSpan={10}
                                    sx={{ color: "#051140" }}
                                >
                                    No data Found
                                </TableCell>
                            </TableBody>
                        )
                    }
                </Table>
            </TableContainer>
            <Pagination
                module={Data}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
            />
        </div>
    );
};

export default ONGOING;

