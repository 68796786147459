import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";
import ProtectedRoutes from "./protectedRoutes";
import MainContainer from "./layout/MainContainer";

const Routing = () => {
  return (
    <Routes>

      {/* unprotected routes */}
      <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />


      {/* Protected routes */}
      <Route element={<ProtectedRoutes />}>
        <Route path="changepassword" element={<Pages.ChangePassword />} />
        <Route path="/dashboard" element={<MainContainer><Pages.DashBoardPage /></MainContainer>} />
        <Route path="/profile" element={<MainContainer><Pages.Profile /></MainContainer>} />
        <Route path="/manage-customers" element={<MainContainer><Pages.ManageUsers /></MainContainer>} />
        <Route path="/manage-customers/userdetails/:id" element={<MainContainer><UserDetails /></MainContainer>} />
        <Route path="/manage-customers/usersform/edit/:id" element={<MainContainer><UsersForm /></MainContainer>} />
        <Route path="/manage-customers/usersform" element={<MainContainer><UsersForm /></MainContainer>} />
        <Route path="/analytics" element={<MainContainer><Pages.Analytics /></MainContainer>} />
        <Route path="/faq" element={<MainContainer><Pages.Faq /></MainContainer>} />
        <Route path="/cms" element={<MainContainer><Pages.ManageCms /></MainContainer>} />
        <Route path="/manage-bulk-orders" element={<MainContainer><Pages.ManageBulkOrders /></MainContainer>} />
        <Route path="/manage-bulk-orders/details/:id" element={<MainContainer><Pages.BulkOrderDetails /></MainContainer>} />
        <Route path="/product-order-management" element={<MainContainer><Pages.ManageServiceProvider /></MainContainer>} />
        <Route path="/product-order-management/details/:id" element={<MainContainer><Pages.ProductOrderDetails /></MainContainer>} />
        <Route path="/manage-notifications" element={<MainContainer><Pages.ManageNotifications /></MainContainer>} />
        <Route path="/manage-notifications/add" element={<MainContainer><Pages.AddNotification /></MainContainer>} />
        <Route path="/recieved-notifications" element={<MainContainer><Pages.RecievedNotifications /></MainContainer>} />
        <Route path="/customer-support" element={<MainContainer><Pages.CustomerSupport /></MainContainer>} />
        <Route path="/manage-subAdmin" element={<MainContainer><Pages.ManageSubAdmin /></MainContainer>} />
        <Route path="/manage-subAdmin/add" element={<MainContainer><Pages.AddSubAdmin /></MainContainer>} />
        <Route path="/manage-subAdmin/edit/:id" element={<MainContainer><Pages.AddSubAdmin /></MainContainer>} />
        <Route path="/manage-categories" element={<MainContainer><Pages.ManageCategories /></MainContainer>} />
        <Route path="/manage-categories/add" element={<MainContainer><Pages.AddCategories /></MainContainer>} />
        <Route path="/manage-categories/details/:id" element={<MainContainer><Pages.SubCategories /></MainContainer>} />
        <Route path="/manage-categories/edit/:id" element={<MainContainer><Pages.AddCategories /></MainContainer>} />
        <Route path="/manage-amenities" element={<MainContainer><Pages.ManageAmenities /></MainContainer>} />
        <Route path="/manage-amenities/add" element={<MainContainer><Pages.AddAmenities /></MainContainer>} />
        <Route path="/manage-reviews" element={<MainContainer><Pages.ManageReviews /></MainContainer>} />
        <Route path="/manage-reviews/details/:id" element={<MainContainer><Pages.ReviewDetails /></MainContainer>} />
        <Route path="/manage-revenue" element={<MainContainer><Pages.ManageRevenue /></MainContainer>} />
        <Route path="/manage-revenue/payment-details/:id" element={<MainContainer><Pages.PaymentDetails /></MainContainer>} />
        <Route path="/ad-banner-management" element={<MainContainer><Pages.AdBanner /></MainContainer>} />
        <Route path="/ad-banner-management/add" element={<MainContainer><Pages.AddBanner /></MainContainer>} />
        <Route path="/ad-banner-management/edit/:id" element={<MainContainer><Pages.AddBanner /></MainContainer>} />
        <Route path="/inventory-management" element={<MainContainer><Pages.ManageInventory /></MainContainer>} />
        <Route path="/inventory-management/details/:id" element={<MainContainer><Pages.ContentDetails /></MainContainer>} />
        <Route path="/inventory-management/details/variants/:id" element={<MainContainer><Pages.ManageVariants /></MainContainer>} />
        <Route path="/manage-products" element={<MainContainer><Pages.ManageProducts /></MainContainer>} />
        <Route path="/manage-products/details/:id" element={<MainContainer><Pages.ContentDetails /></MainContainer>} />
        <Route path="/manage-products/add" element={<MainContainer><Pages.AddProductForm /></MainContainer>} />
        <Route path="/manage-products/edit/:id" element={<MainContainer><Pages.AddProductForm /></MainContainer>} />
        <Route path="/discount-and-coupon" element={<MainContainer><Pages.ManageDiscount /></MainContainer>} />
        <Route path="/discount-and-coupon/add" element={<MainContainer><Pages.AddDiscount /></MainContainer>} />
        <Route path="/discount-and-coupon/edit/:id" element={<MainContainer><Pages.AddDiscount /></MainContainer>} />
        <Route path="/manage-sizes" element={<MainContainer><Pages.ManageSizes /></MainContainer>} />
        <Route path="/manage-sizes/add-size" element={<MainContainer><Pages.AddSize /></MainContainer>} />
        <Route path="//manage-sizes/edit-size/:id" element={<MainContainer><Pages.AddSize /></MainContainer>} />
        <Route path="/manage-geofence" element={<MainContainer><Pages.ManageGeofence /></MainContainer>} />
        <Route path="/manage-geofence/add" element={<MainContainer><Pages.AddGeofence /></MainContainer>} />
        <Route path="/manage-geofence/edit/:id" element={<MainContainer><Pages.AddGeofence /></MainContainer>} />
        <Route path="/manage-factory-tour" element={<MainContainer><Pages.FactoryTourIndex /></MainContainer>} />
        <Route path="/manage-factory-tour/add" element={<MainContainer><Pages.AddFactoryTour /></MainContainer>} />
        <Route path="/manage-factory-tour/edit/:id" element={<MainContainer><Pages.AddFactoryTour /></MainContainer>} />
      </Route>
    </Routes>
  );
};

export default Routing;
