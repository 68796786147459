import {
    Box,
    IconButton,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    FormControl,
    Typography,
    Select,
    MenuItem,
    Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import React, { useEffect, useState } from "react";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import { OrderData } from "../../types/General";
import { Pagination } from "../../components";
import moment from "moment";

interface TabProps {
    Data: OrderData[];
    count: number;
    fetchOrderData: () => void;
    page: number;
    onPageChange: (newPage: number) => void;
}

const ALL: React.FC<TabProps> = ({ Data, count, fetchOrderData, page, onPageChange }) => {
    const navigate = useNavigate();
    const label = { inputProps: { "aria-label": "Switch demo" } };
    let totalPages = Math.ceil(count / 10);

    function formatNumberWithCommas(value: string | number) {
        const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
        const [integer, decimal] = valueStr.split('.');
        const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
        return result;
    }

    useEffect(() => {
        fetchOrderData()
    }, [page])
    return (
        <div>
            <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">S.No</TableCell>
                            <TableCell align="center">Date</TableCell>
                            <TableCell align="center">Order Id</TableCell>
                            <TableCell align="center">Customer Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Product Name</TableCell>
                            <TableCell align="center">Total cost</TableCell>
                            <TableCell align="center">Payment Received</TableCell>
                            <TableCell align="center">Balance Payment</TableCell>
                            <TableCell align="center">Product status</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        Data?.length ? (
                            Data?.map((row, i) => {
                                return (
                                    <TableBody>
                                        <TableRow >
                                            <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                                            <TableCell align="center">{moment(row?.createdAt).format("DD-MM-YYYY") || "-"}</TableCell>
                                            <TableCell align="center" > {row?.orderId || "-"}</TableCell>
                                            <TableCell align="center">{row?.user?.fullName ? (row?.user?.fullName) : (row?.user?.firstName + " " + row?.user?.lastName) || "-"}</TableCell>
                                            <TableCell align="center">{row?.address?.email || "-"}</TableCell>
                                            <TableCell align="center">{row?.productVariant?.name || "-"}</TableCell>
                                            <TableCell align="center">AED{" "}{row?.totalPriceOfProduct ? formatNumberWithCommas(String(row?.totalPriceOfProduct)) : "-"}</TableCell>
                                            <TableCell align="center">AED{" "}{row?.advanceAmountPaid ? formatNumberWithCommas(String(row?.advanceAmountPaid)) : "-"}</TableCell>
                                            <TableCell align="center">AED{" "}{row?.pendingAmountLeft ? formatNumberWithCommas(String(row?.pendingAmountLeft)) : "-"}</TableCell>
                                            <TableCell align="center">
                                                {(() => {
                                                    switch (row?.status) {
                                                        case 1:
                                                            return 'Pending';
                                                        case 2:
                                                            return 'Ongoing';
                                                        case 3:
                                                            return 'Completed';
                                                        case 4:
                                                            return 'Cancelled';
                                                        case 5:
                                                            return 'Packed';
                                                        case 6:
                                                            return 'Shipped';
                                                        case 7:
                                                            return 'Rejected';
                                                        case 8:
                                                            return 'Returned';
                                                        case 9:
                                                            return 'Refunded';
                                                        default:
                                                            return '-';
                                                    }
                                                })()}
                                            </TableCell>
                                            <TableCell>
                                                <Box className="table_actions">
                                                    <Tooltip title="View">
                                                        <IconButton
                                                            onClick={() =>
                                                                navigate(`/product-order-management/details/${row?._id}`, { state: { type: "ALL" } })
                                                            }
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                );
                            })
                        ) : (
                            <TableBody>
                                <TableCell
                                    align="center"
                                    colSpan={10}
                                    sx={{ color: "#051140" }}
                                >
                                    No data Found
                                </TableCell>
                            </TableBody>
                        )
                    }

                </Table>
            </TableContainer>
            <Pagination
                module={Data}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
            />
        </div>
    );
};

export default ALL;

