import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isValidInput } from "../../utils/validations";
import { useDeleteGeofenceMutation, useLazyGetGeofenceQuery, useUpdateGeofenceMutation } from "../../services/geofence";
import Loader from "../../constants/Loader";
import { Pagination, WarnModal } from "../../components";
import { showError, showToast } from "../../constants/toast";
import { handleDelete } from "../../utils/commonFunctions";
import { CommonBody, GeofenceData, GeofenceResponse } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageGeofence = () => {
  const [getAllGeofence, { isLoading }] = useLazyGetGeofenceQuery();
  const [geoData, setGeoData] = useState<GeofenceData[]>([]);
  const navigate = useNavigate();
  const [updateGeofence] = useUpdateGeofenceMutation();
  const [selectedId, setSelectedId] = useState<string>("")
  const [page, setPage] = useState<number>(1)
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [deleteGeofence] = useDeleteGeofenceMutation();
  let totalPages = Math.ceil(totalCount / 10);
  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];



  //update user status api
  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateGeofence({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Geofence status updated successfully");
        await fetchGeofence();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating geofence status");
    }
  };

  const fetchGeofence = async () => {
    try {
      const res = await getAllGeofence({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim()
      }).unwrap();
      if (res?.statusCode === 200) {
        setGeoData(res?.data?.data)
        setTotalCount(res?.data?.count)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }



  useEffect(() => {
    fetchGeofence()
  }, [searchTerm, debouncedSearchTerm, page])



  const checkPermission = () => {
    const permission =userPermissions;
    console.log('permission: ', permission);
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Geofence"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };


  useEffect(() => {
    checkPermission();
  }, [UserData])


  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Geofence</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }
                }
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || UserData?.role === 1 ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-geofence/add")}
                  >
                    Add Geofence
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {geoData?.length ? (
                  geoData.map((row, i) => (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                        <TableCell align="center">{row?.geofenceName}</TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateUserStatus(row?._id, !row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isEdit || UserData?.role === 1 ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() => navigate(`/manage-geofence/edit/${row?._id}`)}
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isDelete || UserData?.role === 1 ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setSelectedId(row?._id)
                                    setOpen(true)
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                  )
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      No Data Found
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <Pagination
        module={geoData}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        name="Geofence"
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(deleteGeofence, selectedId, fetchGeofence)}
      />
    </div>
  );
};

export default ManageGeofence;
