import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  Card,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,
  Typography,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Payments } from "../../features";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageRevenue = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Payments</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <h2>All Payments</h2>
              </Grid>

              {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                <h4>Total Card Payments: 2000 </h4>
                <h4>Total other Payments: 3000</h4>
              </Grid> */}
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Payments />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default ManageRevenue;
