import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";

import MainContainer from "../../../layout/MainContainer";
import { DocumentViewer } from "../../../components";
import { useLazyGetOrderByIdQuery } from "../../../services/orders";
import { showError } from "../../../constants/toast";
import { OrderData } from "../../../types/General";

const ProductOrderDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [getdetails] = useLazyGetOrderByIdQuery();
  const [details, setDetails] = useState<OrderData>();
  console.log(state)
  const navigate = useNavigate();

  function formatNumberWithCommas(value: string | number) {
    const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
    const [integer, decimal] = valueStr.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let result = decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
    return result;
  }

  const fetchById = async (id: string) => {
    try {
      const response = await getdetails({ id }).unwrap();
      if (response?.statusCode === 200) {
        setDetails(response?.data);
        console.log(setDetails, "setDetails");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      fetchById(id);
    }
  }, [])

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Details</h1>
          <Box className="cards_header_right">
            {state?.type === "PAST" || state?.type === "ONGOING" ? <Button className="btn btn_primary">Generate Invoice</Button> : null}
            <Button
              className="btn btn_primary"
              onClick={() => {
                state.details === "userView" ? navigate("/manage-customers/userdetails/" + id) : navigate("/product-order-management");
              }}
            >
              Back
            </Button>
          </Box>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img src={details?.productVariant?.images[0]?.src ? details?.productVariant?.images[0]?.src : "/static/images/user_placeholder.png"} alt="" />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Customer Name</Typography>
                      <Typography component="p">{details?.user?.fullName || "-"} </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {details?.address?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p"> {details?.user?.countryCode
                        ? (details?.user?.countryCode.includes("+") ? "" : "+") +
                        details?.user?.countryCode
                        : null}{" "}
                        {details?.user?.phone || "-"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Product Name</Typography>
                      <Typography component="p">{details?.productVariant?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5"> Product Category</Typography>
                      <Typography component="p">{details?.categoryName || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5"> Product Sub-Category</Typography>
                      <Typography component="p">{details?.subCategoryName || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Product Status</Typography>
                      <Typography component="p">
                        {(() => {
                          switch (details?.status) {
                            case 1:
                              return "Pending";
                            case 2:
                              return "Ongoing";
                            case 3:
                              return "Completed";
                            case 4:
                              return "Cancelled";
                            default:
                              return "-";
                          }
                        })()}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Quantity</Typography>
                      <Typography component="p">{details?.quantity || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Vat amount</Typography>
                      <Typography component="p">{details?.vatAmount ? "AED " : ""}{details?.vatAmount ? formatNumberWithCommas(String(details?.vatAmount)) : "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Sale Discount</Typography>
                      <Typography component="p">{details?.saleDiscount ? "AED " : ""}{details?.saleDiscount || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Advance payment</Typography>
                      <Typography component="p">{details?.advanceAmountPaid ? "AED " : ""}{details?.advanceAmountPaid ? formatNumberWithCommas(String(details?.advanceAmountPaid)): "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Pending Amount</Typography>
                      <Typography component="p">{details?.pendingAmountLeft ? "AED " : ""}{details?.pendingAmountLeft ? formatNumberWithCommas(String(details?.pendingAmountLeft)) : "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Cost</Typography>
                      <Typography component="p">{details?.subTotal ? "AED " : ""}{details?.subTotal? formatNumberWithCommas(String(details?.subTotal)) : "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Delivery Address</Typography>
                      <Typography component="p">{details?.address?.address}</Typography>
                    </Box>
                  </Grid>
                  {state?.type === "PAST" ? <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Delivery Date</Typography>
                      <Typography component="p">
                        {details?.deliveredAt ? new Date(details.deliveredAt).toLocaleDateString() : ""}
                      </Typography>
                    </Box>
                  </Grid> : null}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ProductOrderDetails;
