import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import MainContainer from "../../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "../Users.scss";
import {
  Details,
  OrderHistory,
} from "../../../features";
import { useLazyGetProductsByIdQuery, useLazyGetUserByIdQuery, useLazyGetUserReportsQuery } from "../../../services/users";
import { OrderData, UserData } from "../../../types/General";
import { showError } from "../../../constants/toast";
import { useLazyGetOrdersByUserQuery } from "../../../services/orders";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const navigate = useNavigate();
  const [getUserById] = useLazyGetUserByIdQuery();
  const [productData] = useLazyGetOrdersByUserQuery();
  const { id } = useParams();
  const [total, setTotal] = useState<number>(0);
  const [userDetails, setUserDetails] = useState<UserData>();
  const [orderDetails, setOrderDetails] = useState<OrderData[]>([]);

  const fetchById = async (id: string) => {
    try {
      const response = await getUserById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setUserDetails(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const fetchOrderData = async (id: string) => {
    try {
      const res = await productData({ id }).unwrap();
      if (res.statusCode === 200) {
        setOrderDetails(res?.data?.data)
        setTotal(res?.data?.count)
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  }

  useEffect(() => {
    if (id) {
      fetchById(id);
      fetchOrderData(id);
    }
  }, []);


  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Customer Profile</h1>
          <Box className="cards_header_right">
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-customers");
              }}
            >
              Back
            </Button>
          </Box>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="User Details" {...a11yProps(0)} />
                <Tab label="Order History" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Details userDetails={userDetails} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <OrderHistory orderDetails={orderDetails} total={total} />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default UserDetails;
