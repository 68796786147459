import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import { useDeleteSubAdminMutation, useLazyGetSubAdminQuery, useUpdateSubAdminMutation } from "../../services/subAdmin";
import { showError, showToast } from "../../constants/toast";
import Loader from "../../constants/Loader";
import { CommonBody, subAdminType } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { Pagination, WarnModal } from "../../components";
import { handleDelete } from "../../utils/commonFunctions";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageSubAdmin = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [getAllSubAdmins, { isLoading }] = useLazyGetSubAdminQuery();
  const [changeSubAdminStatus] = useUpdateSubAdminMutation();
  const [deleteSubAdmin] = useDeleteSubAdminMutation();
  const [subData, SetSubData] = useState<subAdminType[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const label = { inputProps: { "aria-label": "Switch demo" } };


  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);


  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Sub-Admin"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };



  //update size status api
  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await changeSubAdminStatus({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Status updated successfully");
        await getSubadmins();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating status");
    }
  };


  const getSubadmins = async () => {
    try {
      const response = await getAllSubAdmins({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        SetSubData(response?.data?.subAdmin || []);
        setTotalCount(response?.data?.count);
      } else {
        SetSubData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };


  useEffect(() => {
    checkPermission();
  }, [UserData])



  useEffect(() => {
    getSubadmins();
  }, [debouncedSearchTerm, page]);


  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> Manage Sub-Admin</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {hidePermission?.isEdit || UserData?.role === 1 ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-subAdmin/add")}
                >
                  Add Sub-Admin
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Phone</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {subData?.length ? (
                subData.map((row, i) => {
                  return (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">  {(page - 1) * 10 + i + 1}</TableCell>
                        <TableCell align="center">{row?.firstName + " " + row?.lastName}</TableCell>
                        <TableCell align="center">{row?.email}</TableCell>
                        <TableCell align="center">{row?.countryCode
                          ? (row?.countryCode.includes("+") ? "" : "+") +
                          row?.countryCode
                          : null}{" "}
                          {row?.phone || "-"}</TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateUserStatus(row?._id, !row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isEdit || UserData?.role === 1 ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() => navigate(`/manage-subAdmin/edit/${row?._id}`)}
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isEdit || UserData?.role === 1 ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={10}>
                    No Sub-Admin Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={subData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
        <WarnModal
          setOpen={setOpen}
          open={open}
          name="subadmin"
          handleDelete={() =>
            handleDelete(deleteSubAdmin, selectedId, getSubadmins)
          }
        />
      </div>
    </div>
  );
};

export default ManageSubAdmin;
