import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDeleteCategoryMutation, useLazyGetAllCategoriesQuery, useUpdateCategoryByIdMutation } from "../../services/categories";
import { showError, showToast } from "../../constants/toast";
import { CommonBody, TruckCategory } from "../../types/General";
import WarnModal from "../../components/WarnModal";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../constants/Loader";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";


const ManageCategories = () => {
  const navigate = useNavigate();
  const UserData = useAuth();
  function createData(Name: string) {
    return { Name };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [deleteCategory] = useDeleteCategoryMutation();
  const [getAllCategories, { isLoading }] = useLazyGetAllCategoriesQuery();
  const [changeStatus] = useUpdateCategoryByIdMutation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [categoryData, setCategoryData] = useState<TruckCategory[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  let totalPages = Math.ceil(totalCount / 10);

  const userPermissions = UserData?.permissions?.length
  ?  (UserData?.permissions)
  : [];

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  //update user status api
  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await changeStatus({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Category status updated successfully");
        await getCategory();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating category status");
    }
  };

  //delete 
  const handleDeleteCategory = async (id: string) => {
    try {
      const res = await deleteCategory({ id }).unwrap();
      if (res?.statusCode === 200) {
        showToast("category deleted successfully");
        await getCategory();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.message || "");
    }
  };



  const getCategory = async () => {
    try {
      const response = await getAllCategories({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data?.category || []);
        setTotalCount(response?.data?.count);
      } else {
        setCategoryData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getCategory();
  }, [debouncedSearchTerm, page]);
  console.log(categoryData)

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Categories"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };


  useEffect(() => {
    checkPermission();
  }, [UserData])

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Categories</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }
                }
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || UserData?.role === 1 ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-categories/add", { state: { type: "add" } })}
                  >
                    Add Category
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Image</TableCell>
                    <TableCell align="center">Category Name</TableCell>
                    <TableCell align="center">Sub-category</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {categoryData?.length ? (
                  categoryData.map((row, i) => (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{" "}
                          {(page - 1) * 10 + i + 1}</TableCell>
                        <TableCell align="center">
                          <figure className="user_img">
                            <img
                              src={row?.image ? row?.image :"/static/images/pictures.png"}
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell align="center">{row?.name}</TableCell>
                        <TableCell align="center"><Button
                          className="btn btn_primary"
                          onClick={() =>
                            navigate(`/manage-categories/details/${row?._id}`, { state: row.name })
                          } >View sub categories</Button></TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateUserStatus(row?._id, !row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isEdit || UserData?.role === 1 ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() => navigate(`/manage-categories/edit/${row?._id}`)}
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isDelete || UserData?.role === 1 ? (
                              <Tooltip title="Delete">
                                <IconButton>
                                  <DeleteIcon
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedId(row?._id);
                                    }} />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      No Category Found
                    </TableCell>
                  </TableBody>
                )
                }
              </Table>
            </TableContainer>
          </Box>
        </Card>
        <Pagination
          module={categoryData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div >
      <WarnModal
        name="Category"
        setOpen={setOpen}
        open={open}
        handleDelete={() => handleDeleteCategory(selectedId)}
      />
    </div >
  );
};

export default ManageCategories;
