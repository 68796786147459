import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { Box, Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useLazyGetAllGeofenceQuery } from "../../services/geofence";
import { Dashboard, GeofenceData, TruckCategory } from "../../types/General";
import { useLazyGetDashboardQuery, useLazyGetOrderGraphQuery, useLazyGetProductGraphQuery, useLazyGetRevenueGraphQuery, useLazyGetUserGraphQuery } from "../../services/dashboard";
import { showError } from "../../constants/toast";
import { useLazyGetCategoriesQuery } from "../../services/categories";
import { ExportModal1, ExportModal2, ExportModal3, ExportModal4 } from "../../components";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

type Total = {
  totalUser: number;
  totalRevenue: number;
  totalProduct: number;
  totalOrder: number;
}

const Analytics = () => {
  const [getAllGeofence] = useLazyGetAllGeofenceQuery();
  const navigate = useNavigate();
  const [geoData, setGeoData] = useState<GeofenceData[]>([]);
  const [categoryData, setCategoryData] = useState<TruckCategory[]>([]);
  const [getAllCategories] = useLazyGetCategoriesQuery();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedGeofence, setSelectedGeofence] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState<number>(7);
  const [getDashboard] = useLazyGetDashboardQuery();
  const [dashData, setDashData] = useState<Dashboard>();
  const [userGraph] = useLazyGetUserGraphQuery();
  const [productGraph] = useLazyGetProductGraphQuery();
  const [orderGraph] = useLazyGetOrderGraphQuery();
  const [earningGraph] = useLazyGetRevenueGraphQuery();
  const [userGraphData, setUserGraphData] = useState<number[]>([]);
  const [userGraphName, setUserGraphName] = useState<string[]>([]);
  const [orderGraphData, setOrderGraphData] = useState<number[]>([]);
  const [orderGraphName, setOrderGraphName] = useState<string[]>([]);
  const [earningGraphData, setgraphEarningData] = useState<number[]>([]);
  const [earningGraphName, setgraphEarningName] = useState<string[]>([]);
  const [productGraphData, setgraphProductData] = useState<number[]>([]);
  const [productGraphName, setgraphProductName] = useState<string[]>([]);
  const [open1, setOpen1] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [open3, setOpen3] = useState<boolean>(false);
  const [open4, setOpen4] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<Total>({
    totalUser: 0,
    totalRevenue: 0,
    totalProduct: 0,
    totalOrder: 0
  })

  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);


  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Reports and Analytics"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };


  const graphOrderData = {
    labels: orderGraphName,
    datasets: [
      {
        label: "Orders",
        data: orderGraphData,
        borderColor: "#029be0",
        backgroundColor: "#029be0",
      },
    ],
  };

  const graphUsersData = {
    labels: userGraphName,
    datasets: [
      {
        label: "Users",
        data: userGraphData,
        borderColor: "#029be0",
        backgroundColor: "#029be0",
      },
    ],
  };

  const graphProductData = {
    labels: productGraphName,
    datasets: [
      {
        label: "Products",
        data: productGraphData,
        borderColor: "#029be0",
        backgroundColor: "#029be0",
      },
    ],
  };

  const graphRevenueData = {
    labels: earningGraphName,
    datasets: [
      {
        label: "Earnings",
        data: earningGraphData,
        borderColor: "#029be0",
        backgroundColor: "#029be0",
      },
    ],
  };

  const getProductGraph = async () => {
    try {
      const response = await productGraph({ type: selectedTime, categoryId: selectedCategory, geofenceId: selectedGeofence }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = Object?.keys(response?.data?.totalProduct) || [];
        const value = Object?.values(response?.data?.totalProduct) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setgraphProductName(userKeys)
        setgraphProductData(values)

        let dummy = 0;
        value.map((val: any) => dummy = dummy + Number(val))
        setTotalCount((pre: any) => ({
          ...pre,
          totalProduct: dummy
        }))
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getUserGraph = async () => {
    try {
      const response = await userGraph({ type: selectedTime, categoryId: selectedCategory, geofenceId: selectedGeofence }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = Object?.keys(response?.data?.totalUsers) || [];
        const value = Object?.values(response?.data?.totalUsers) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setUserGraphName(userKeys)
        setUserGraphData(values)

        let dummy = 0;
        value.map((val: any) => dummy = dummy + Number(val))
        setTotalCount((pre: any) => ({
          ...pre,
          totalUser: dummy
        }))
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getOrderGraph = async () => {
    try {
      const response = await orderGraph({ type: selectedTime, categoryId: selectedCategory, geofenceId: selectedGeofence }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = Object?.keys(response?.data?.totalOrder) || [];
        const value = Object?.values(response?.data?.totalOrder) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setOrderGraphName(userKeys)
        setOrderGraphData(values)

        let dummy = 0;
        value.map((val: any) => dummy = dummy + Number(val))
        setTotalCount((pre: any) => ({
          ...pre,
          totalOrder: dummy
        }))
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };


  const getEarningGraph = async () => {
    try {
      const response = await earningGraph({ type: selectedTime, categoryId: selectedCategory, geofenceId: selectedGeofence }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = Object?.keys(response?.data?.revenuGenerated) || [];
        const value = Object?.values(response?.data?.revenuGenerated) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setgraphEarningName(userKeys)
        setgraphEarningData(values)
        let dummy = 0;
        value.map((val: any) => dummy = dummy + Number(val))
        setTotalCount((pre: any) => ({
          ...pre,
          totalRevenue: dummy
        }))
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getCategory = async () => {
    try {
      const response = await getAllCategories({
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data?.category || []);
      } else {
        setCategoryData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const fetchGeofence = async () => {
    try {
      const res = await getAllGeofence({}).unwrap();
      if (res?.statusCode === 200) {
        setGeoData(res?.data?.data)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }

  const fetchDashboard = async () => {
    try {
      const res = await getDashboard({
        timeFilter: selectedTime,
        categoryId: selectedCategory,
        geofenceId: selectedGeofence,
      }).unwrap();
      if (res?.statusCode === 200) {
        setDashData(res?.data);
        console.log(res, "res...........");
      }
    } catch (error) {
      console.log(error);

    }
  }
  const handleChangeGeofence = (event: any) => {
    const value = event.target.value;
    setSelectedGeofence(value);
  };

  const handleChangeCategory = (event: any) => {
    const value = event.target.value;
    setSelectedCategory(value);
  };

  function convertToInternationalCurrencySystem(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }

  const isFilterApplied = selectedCategory !== '' || selectedGeofence !== '';


  const handleClearFilters = () => {
    setSelectedCategory('');
    setSelectedGeofence('');
  };

  useEffect(() => {
    fetchGeofence();
    fetchDashboard();
    getCategory();
  }, [])



  useEffect(() => {
    checkPermission();
  }, [UserData])

  useEffect(() => {
    getUserGraph();
    getOrderGraph();
    getEarningGraph();
    getProductGraph();
  }, [selectedTime, selectedCategory, selectedGeofence]);

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <div className="flex">
            <h1 className="mn_hdng">Reports and Analytics</h1>
            {/* <Box className="cards_header_right">
              <Button className="btn btn_primary"
                onClick={handleExportCsv}
              >
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box> */}
          </div>
        </div>
        <Grid item lg={3} md={3} sm={3} xs={12}>
          <h2 className="mn_hdng">
            <FormControl>
              <Select
                className="select_div "
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                inputProps={{
                  "aria-label": "Without label",
                }}
                displayEmpty
                value={selectedCategory}
                onChange={handleChangeCategory}
              >
                <MenuItem value="" disabled>
                  Select Category
                </MenuItem>
                {categoryData?.map((item, index) => (
                  <MenuItem value={item?._id}>{item?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ marginLeft: "5px" }}>
              <Select
                className="select_div "
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                value={selectedGeofence}
                onChange={handleChangeGeofence}
              >
                <MenuItem value="" disabled>
                  Select Location
                </MenuItem>
                {geoData?.map((item, index) => (
                  <MenuItem value={item?._id}>{item?.geofenceName}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "180px", marginLeft: "5px" }}>
              <NativeSelect
                className="select_div "
                id="demo-simple-select"
                defaultValue={7}
                inputProps={{
                  name: "report",
                  id: "uncontrolled-native",
                }}
                onChange={(event) => setSelectedTime(Number(event.target.value))}
              >
                <option value={1}>All Time</option>
                <option value={2}>Last 1 year</option>
                {/* <option value={3}>Last 6 months</option> */}
                {/* <option value={4}>Last 3 months</option> */}
                <option value={5}>Last month</option>
                <option value={6}>Last week</option>
                <option value={7}>Today</option>
              </NativeSelect>
            </FormControl>
            {isFilterApplied && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ marginLeft: "3px", marginTop: "5px", backgroundColor: "#029be0", color: "white", textTransform: "capitalize" }}
                onClick={handleClearFilters}
              >
                Clear filters
              </Button>
            )}
          </h2>
        </Grid>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total registered users  ({totalCount?.totalUser || 0})
                <Button
                  className="btn btn_primary"
                  onClick={() => setOpen1(true)}
                >
                  <FileDownloadIcon /> Export
                </Button>
              </h2>

              <LineChart data={graphUsersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total orders ({totalCount?.totalOrder ||0})
                <Button
                  className="btn btn_primary"
                  onClick={() => setOpen4(true)}
                >
                  <FileDownloadIcon /> Export
                </Button>
              </h2>
              <LineChart data={graphOrderData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Earnings (AED {convertToInternationalCurrencySystem(totalCount?.totalRevenue || 0)})
                <Button
                  className="btn btn_primary"
                  onClick={() => setOpen3(true)}
                >
                  <FileDownloadIcon /> Export
                </Button>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">Total Products ({totalCount?.totalProduct ||0})
                <Button
                  className="btn btn_primary"
                  onClick={() => setOpen2(true)}
                >
                  <FileDownloadIcon /> Export
                </Button></h2>
              <LineChart data={graphProductData} />
            </div>
          </Grid>
        </Grid>
      </div>
      <ExportModal1
        open={open1}
        setOpen={setOpen1} />
      <ExportModal2
        open={open2}
        setOpen={setOpen2}
      />
      <ExportModal3
        open={open3}
        setOpen={setOpen3} />
      <ExportModal4
        open={open4}
        setOpen={setOpen4}
      />
    </div>
  );
};

export default Analytics;
