import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { BulkOrderData } from "../../types/General";
import { showError } from "../../constants/toast";
import MainContainer from "../../layout/MainContainer";
import { Pagination, SearchBar } from "../../components";
import { isValidInput } from "../../utils/validations";
import { useLazyGetBulkOrdersQuery } from "../../services/orders";
import Loader from "../../constants/Loader";

const ManageBulkOrders = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [page, setPage] = useState(1);
    const UserData = useAuth();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [getAllOrders, { isLoading }] = useLazyGetBulkOrdersQuery();
    const [orderData, setOrderData] = useState<BulkOrderData[]>([]);
    const onPageChange = (newPage: number) => {
        setPage(newPage);
    };
    let totalPages = Math.ceil(totalCount / 10);
    const [hidePermission, setHidePermission] = useState<
        Permissions | null | undefined
    >(null);


    const userPermissions = UserData?.permissions?.length
        ? (UserData?.permissions)
        : [];

    const checkPermission = () => {
        const permission = userPermissions;
        if (permission?.length) {
            let idx = -1;
            idx = permission?.findIndex(
                (ele: Permissions) => ele?.label === "Manage Bulk Oredrs"
            );
            if (idx > -1) {
                setHidePermission(permission[idx]);
            } else {
                navigate(-1);
            }
        }
    };



    useEffect(() => {
        checkPermission();
    }, [UserData])



    //get all  api
    const fetchOrders = async () => {
        try {
            const response = await getAllOrders({
                query: debouncedSearchTerm.trim(),
                page: page,
            },).unwrap();
            if (response?.statusCode === 200) {
                setTotalCount(response?.data?.count);
                setOrderData(response?.data?.bulkOrders)
            } else {
                setOrderData([]);
            }
        } catch (error: any) {
            showError(error?.data?.message || "");
        }
    };
    console.log(orderData, "orderData.....................");


    function convertToInternationalCurrencySystem(labelValue: number) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e9
            ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
            : // Six Zeroes for Millions
            Math.abs(Number(labelValue)) >= 1.0e6
                ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
                : // Three Zeroes for Thousands
                Math.abs(Number(labelValue)) >= 1.0e3
                    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
                    : Math.abs(Number(labelValue));
    }


    useEffect(() => {
        fetchOrders();
    }, [page, debouncedSearchTerm])


    const label = { inputProps: { "aria-label": "Switch demo" } };

    return (
        <div>
            <Loader isLoad={isLoading} />
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Manage Bulk Orders</h1>
                </div>
                <Card className="cards">
                    <Box className="custom_tabs">
                        <Box className="cards_header">
                            <SearchBar
                                searchTerm={searchTerm}
                                setDebouncedSearchTerm={setDebouncedSearchTerm}
                                value={searchTerm}
                                onCross={() => setSearchTerm("")}
                                onChange={(val: any) => {
                                    if (isValidInput(val.target.value)) {
                                        setSearchTerm(val.target.value);
                                    }
                                }}
                            />
                        </Box>
                        <TableContainer className="table_container">
                            <Box className="heading"></Box>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">S.No</TableCell>
                                        <TableCell align="center"> Customer name</TableCell>
                                        <TableCell align="center"> Email</TableCell>
                                        <TableCell align="center"> Phone </TableCell>
                                        <TableCell align="center">Product name</TableCell>
                                        <TableCell align="center">Quantity</TableCell>
                                        <TableCell align="center">Total Price</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                {orderData?.length ? (
                                    orderData.map((row, i) => {
                                        return (
                                            <TableBody key={row?._id}>
                                                <TableRow>
                                                    <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                                                    <TableCell align="center">
                                                        {row?.userId?.fullName ? (row?.userId?.fullName) : (row?.userId?.firstName + " " + row?.userId?.lastName) || "-"}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row?.userId?.email || "-"}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row?.userId?.countryCode
                                                            ? (row?.userId?.countryCode.includes("+") ? "" : "+") +
                                                            row?.userId?.countryCode
                                                            : null}{" "}
                                                        {row?.userId?.phone || "-"}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row?.productName || "-"}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row?.quantity || "-"}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        AED{" "}{convertToInternationalCurrencySystem(row?.totalPrice || 0)}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Box className="table_actions">
                                                            <Tooltip title="View">
                                                                <IconButton>
                                                                    <VisibilityIcon
                                                                        onClick={() => {
                                                                            navigate(`/manage-bulk-orders/details/${row?._id}`)
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        );
                                    })
                                ) : (
                                    <TableBody>
                                        <TableCell
                                            align="center"
                                            colSpan={10}
                                            sx={{ color: "#051140" }}
                                        >
                                            No Data Found
                                        </TableCell>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Box>
                </Card>
                <Pagination
                    module={orderData}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                />

            </div>
        </div>

    );
};

export default ManageBulkOrders;
