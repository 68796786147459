import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import MainContainer from "../../layout/MainContainer";
import { useLazyDeleteRatingsQuery, useLazyGetRatingQuery, useLazyProductDetailsQuery } from "../../services/products";
import { showError, showToast } from "../../constants/toast";
import Rating from '@mui/material/Rating';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ProductData, ratingType } from "../../types/General";
import Loader from "../../constants/Loader";
import { Pagination, SearchBar, WarnModal } from "../../components";
import { isValidInput } from "../../utils/validations";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { id } = useParams();

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ContentDetails = () => {
  const navigate = useNavigate();
  const [getProducts] = useLazyProductDetailsQuery()
  const [product, setProduct] = useState<ProductData>()
  const [getAllRatings, { isLoading }] = useLazyGetRatingQuery();
  const [ratings, setRatings] = useState<ratingType[]>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [viewAll, setViewAll] = useState<boolean>(false);
  const buttonText = viewAll ? "Show Less" : "View All";
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const { id } = useParams()
  const [value, setValue] = React.useState(0);
  const [deleteRating] = useLazyDeleteRatingsQuery();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const handleDeleteRatings = async (id: string) => {
    try {
      const res = await deleteRating({ id }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Rating deleted successfully");
        await fetchRatings();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.message || "");
    }
  };

  function formatNumberWithCommas(value: string | number) {
    const valueStr = typeof value === 'number' ? value.toFixed(2) : value;
    const [integer, decimal] = valueStr.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let result= decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
    console.log('result123: ', result);
    return result;
}

  const fetchDetails = async (id: string) => {
    try {

      const res = await getProducts({
        id: id
      }).unwrap()

      if (res?.statusCode === 200) {
        setProduct(res?.data || [])
      }

    } catch (error: any) {
      showError(error?.data?.message)
    }
  }

  //get all user api
  const fetchRatings = async () => {
    try {
      const response = await getAllRatings({
        id: id,
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim()
      },).unwrap();
      if (response?.statusCode === 200) {
        setTotalCount(response?.data?.count);
        setRatings(response?.data?.data || [])
      } else {
        setRatings([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    if (id) {
      fetchDetails(id)
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchRatings();
    }
  }, [page, debouncedSearchTerm]);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Product Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-products");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Product Details" {...a11yProps(0)} />
                <Tab label="Rating and reviews" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2} className="view_box">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Product Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="view_box_list">
                    <ul className="prdct_images">
                      {product?.images?.length ? (
                        <>
                          {product?.images?.slice(0, viewAll ? product?.images.length : 5).map((item: any, i: any) => {
                            return (
                              <li key={i}>
                                {item.type === 'image' ? (
                                  <figure>
                                    <img src={item.src} alt="upload" />
                                  </figure>
                                ) : (
                                  <figure>
                                    <video controls preload="metadata" autoPlay={false} width="100%" height="100%" style={{ objectFit: "cover", borderRadius: "20px" }}>
                                      <source src={item.src} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                  </figure>
                                )}
                              </li>
                            );
                          })}

                          {product?.images?.length > 5 && (
                            <Button
                              className="btn btn_primary"
                              onClick={() => {
                                setViewAll(!viewAll)
                              }}
                            >
                              {buttonText}
                            </Button>
                          )}
                        </>
                      ) : null}
                    </ul>
                    <Grid container spacing={3}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Product Name</Typography>
                          <Typography component="p">{product?.name}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Product category</Typography>
                          <Typography component="p">{[product?.category?.name]}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Product sub-category</Typography>
                          <Typography component="p">{product?.subcategory?.name}</Typography>
                        </Box>
                      </Grid>
                      {product?.outerDiameter ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Outer Diameter (OD)</Typography>
                            <Typography component="p">{product?.outerDiameter || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.innerDiameter ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Inner Diameter (ID)</Typography>
                            <Typography component="p">{product?.innerDiameter || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.width ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Width (W)</Typography>
                            <Typography component="p">{product?.width || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.application ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Application</Typography>
                            <Typography component="p">{product?.application || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.overallHeight ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Overall Height</Typography>
                            <Typography component="p">{product?.overallHeight || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.bearingClearance ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Bearing Clearance</Typography>
                            <Typography component="p">{product?.bearingClearance || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.axialClearance ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Axial Clearance</Typography>
                            <Typography component="p">{product?.axialClearance || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.rollerDiameter ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Ball or Roller Diameter</Typography>
                            <Typography component="p">{product?.rollerDiameter || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.pitchDiameter ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Pitch Diameter</Typography>
                            <Typography component="p">{product?.pitchDiameter || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.contactAngle ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Contact Angle</Typography>
                            <Typography component="p">{product?.contactAngle || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.runout ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Runout</Typography>
                            <Typography component="p">{product?.runout || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.weight ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Weight</Typography>
                            <Typography component="p">{product?.weight || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.overallLength ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Overall Length (L)</Typography>
                            <Typography component="p">{product?.overallLength || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.boltHoleSpacing ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Bolt Hole Spacing</Typography>
                            <Typography component="p">{product?.boltHoleSpacing || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.boltHoleDiameter ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Bolt Hole Diameter</Typography>
                            <Typography component="p">{product?.boltHoleDiameter || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.shaftDiameter ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Shaft Diameter (ID)</Typography>
                            <Typography component="p">{product?.shaftDiameter || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.mountingHeight ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Mounting Height (H)</Typography>
                            <Typography component="p">{product?.mountingHeight || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.distanceFromBaseToCenter ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Distance from Base to Center (BC)</Typography>
                            <Typography component="p">{product?.distanceFromBaseToCenter || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.threadQuality ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Thread Quality</Typography>
                            <Typography component="p">{product?.threadQuality || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.surfaceFinish ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Surface Finish</Typography>
                            <Typography component="p">{product?.surfaceFinish || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.antiCorrosionProperties ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Anti-Corrosion Properties</Typography>
                            <Typography component="p">{product?.antiCorrosionProperties || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.threadLocking ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Thread Locking</Typography>
                            <Typography component="p">{product?.threadLocking || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.compatibilityAndFitment ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Compatibility and Fitment</Typography>
                            <Typography component="p">{product?.compatibilityAndFitment || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.testingAndQualityControl ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Testing and Quality Control</Typography>
                            <Typography component="p">{product?.testingAndQualityControl || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.precisionRating ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Precision Rating</Typography>
                            <Typography component="p">{product?.precisionRating || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.materialComposition ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Material Composition</Typography>
                            <Typography component="p">{product?.materialComposition || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.manufacturingStandards ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Manufacturing Standards</Typography>
                            <Typography component="p">{product?.manufacturingStandards || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.loadCapacity ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Load Capacity</Typography>
                            <Typography component="p">{product?.loadCapacity || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.sealingAndLubrication ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Sealing and Lubrication</Typography>
                            <Typography component="p">{product?.sealingAndLubrication || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.toleranceAndFit ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Tolerance and Fit</Typography>
                            <Typography component="p">{product?.toleranceAndFit || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.durabilityAndLifespan ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Durability and Lifespan</Typography>
                            <Typography component="p">{product?.durabilityAndLifespan || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.speedRpm ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Speed (RPM)</Typography>
                            <Typography component="p">{product?.speedRpm || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.noiseAndVibration ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Noise and Vibration</Typography>
                            <Typography component="p">{product?.noiseAndVibration || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.packagingType ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Packaging Type</Typography>
                            <Typography component="p">{product?.packagingType || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      {product?.marking ? (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">Marking</Typography>
                            <Typography component="p">{product?.marking || "-"}</Typography>
                          </Box>
                        </Grid>
                      ) : ""}

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Typography component="h5"> Product description</Typography>
                          <Typography component="p"> {product?.description || "-"}.</Typography>
                        </Box>
                      </Grid>
                      {/* varients table */}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TableContainer className="table_container">
                          <Box className="heading" sx={{ fontSize: 17, fontWeight: 700 }}> Product varients</Box>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">S.No</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell align="center">Product Cost</TableCell>
                                <TableCell align="center">Product Quantity</TableCell>
                                <TableCell align="center">Quantity Left</TableCell>
                                <TableCell>Product quantity Min-limit for purchase</TableCell>
                                <TableCell>Product quantity Max-limit for purchase</TableCell>
                              </TableRow>
                            </TableHead>
                            {product?.productVariants?.length ? (
                              product.productVariants.map((row, i) => (
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center">{i + 1}</TableCell>
                                    <TableCell align="center">{row?.size}</TableCell>
                                    <TableCell align="center">AED {row?.price ? formatNumberWithCommas(String(row?.price)):""}</TableCell>
                                    <TableCell align="center">{row?.totalQuantity}</TableCell>
                                    <TableCell align="center">{row?.quantityLeft}</TableCell>
                                    <TableCell align="center">{row?.minPurchaseQuantity}</TableCell>
                                    <TableCell align="center">{row?.maxPurchaseQuantity}</TableCell>
                                  </TableRow>
                                </TableBody>
                              ))
                            ) : (
                              <TableBody>
                                <TableCell
                                  align="center"
                                  colSpan={10}
                                  sx={{ color: "#051140" }}
                                >
                                  No varient Found
                                </TableCell>
                              </TableBody>
                            )
                            }
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Card className="cards">
                <Box className="custom_tabs">
                  <Box className="cards_header">
                    <SearchBar
                      searchTerm={searchTerm}
                      setDebouncedSearchTerm={setDebouncedSearchTerm}
                      value={searchTerm}
                      onCross={() => setSearchTerm("")}
                      onChange={(val: any) => {
                        if (isValidInput(val.target.value)) {
                          setSearchTerm(val.target.value);
                        }
                      }}
                    />
                  </Box>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No </TableCell>
                          <TableCell align="center">Customer Name</TableCell>
                          <TableCell align="center">Rating</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      {ratings?.length ? (
                        ratings?.map((row, i) => {
                          return (
                            <TableBody>
                              <TableRow>
                                <TableCell align="center">  {(page - 1) * 10 + i + 1}</TableCell>
                                <TableCell align="center">{row?.userId?.fullName}</TableCell>
                                <TableCell align="center"><Rating name="read-only" value={row?.rating} readOnly /> </TableCell>
                                <TableCell align="center">
                                  <Box className="table_actions">
                                    <Tooltip title="View">
                                      <IconButton
                                        onClick={() => navigate(`/manage-reviews/details/${id}?userId=${row?.userId?._id}`)}
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <IconButton
                                      >
                                        <DeleteIcon
                                          onClick={() => {
                                            setOpen(true);
                                            setSelectedId(row?._id);
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          );
                        })
                      ) : (
                        <TableBody>
                          <TableCell
                            align="center"
                            colSpan={10}
                            sx={{ color: "#051140" }}
                          >
                            No data Found
                          </TableCell>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
              <Pagination
                module={ratings}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <WarnModal
        name="Rating"
        setOpen={setOpen}
        open={open}
        handleDelete={() => handleDeleteRatings(selectedId)}
      />
    </div>
  );
};

export default ContentDetails;