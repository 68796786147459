import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tooltip,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { useDeleteUserMutation, useEditUserMutation, useLazyGetAllUsersQuery, useLazyGetUserByIdQuery, useLazyGetUserCSVQuery, useLazyGetUserExcelQuery, } from "../../services/users";
import { ExportModal1, Pagination } from "../../components";
import { showError, showToast } from "../../constants/toast";
import moment from "moment";
import { CommonBody, UserData } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import WarnModal from "../../components/WarnModal";
import { isValidInput } from "../../utils/validations";
import Loader from "../../constants/Loader";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { Label } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const ManageUsers = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [getUsers, { isLoading }] = useLazyGetAllUsersQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [updateUser] = useEditUserMutation();
  const [manageUser, setManageUser] = useState<UserData[]>([]);
  const [liveUser, setLiveUser] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false)
  const location = useLocation();
  const { state } = location;
  const UserData = useAuth();
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const handleDateChange = (event: any) => {
    setStartDate(event);
  };

  const handleDateChange1 = (event: any) => {
    setEndDate(event);
  };

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);


  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Customers"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };



  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  //get all user api
  const getAllUsers = async () => {
    try {
      const response = await getUsers({
        page: page,
        query: debouncedSearchTerm.trim(),
        liveUsers: liveUser,
        newUsers: newUser,
        fromDate: startDate ? moment(startDate).format(
          'YYYY-MM-DDTHH:mm:ss[Z]',
        ) : "",
        toDate: endDate ? moment(endDate).format(
          'YYYY-MM-DDTHH:mm:ss[Z]',
        ) : "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setTotalCount(response?.data?.count);
        setManageUser(response?.data?.user || [])
      } else {
        setManageUser([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  //update user status api
  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateUser({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("User status updated successfully");
        await getAllUsers();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating user status");
    }
  };

  //delete user api
  const handleDeleteUser = async (id: string) => {
    try {
      const res = await deleteUser({ id }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Customer deleted successfully");
        await getAllUsers();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.message || "");
    }
  };




  useEffect(() => {
    checkPermission();
  }, [UserData])

  useEffect(() => {
    getAllUsers();
  }, [page, debouncedSearchTerm, liveUser, newUser, startDate, endDate]);

  useEffect(() => {
    if (state?.value === "Live") {
      setLiveUser(!liveUser);
      console.log(liveUser, "liveUser...");
    }
  }, [])

  useEffect(() => {
    if (state?.value === "New") {
      setNewUser(true);
    }
  }, [])
  const today = new Date().toISOString().split('T')[0];
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{state?.value === "Live" ? "Live Users" : "Manage Customers"}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header ">

            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />


            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={() => setOpen1(true)}>
                <FileDownloadIcon /> Export
              </Button>
            </Box>
          </Box>
          <Box className="crds_date" >
            <div>
              <Typography>Start Date</Typography>
            
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={startDate}
                  className="adapter_picker"
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            </div>
            <div>
              <Typography>End Date</Typography>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={endDate}
                  className="adapter_picker"
                  onChange={handleDateChange1}

                />
              </LocalizationProvider>
            </div>
            {startDate || endDate ? (
              <Button
                onClick={() => { setStartDate(null); setEndDate(null) }}
                style={{ marginLeft: "1%", borderRadius: "25px", background: "gray",marginTop:"2%" }}
                className="btn btn_primary">Clear</Button>
            ) : ("")}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Created Date</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Customer Name</TableCell>
                  <TableCell align="center">Phone No.</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Account Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              {manageUser?.length ? (
                manageUser?.map((row: UserData, i: number) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">{" "}
                          {(page - 1) * 10 + i + 1}</TableCell>
                        <TableCell align="center">{moment(row?.createdAt).format("DD/MM/YYYY")}</TableCell>
                        <TableCell align="center">
                          <figure className="user_img">
                            <img
                              src={row?.image ? (row?.image) : ("/static/images/user_placeholder.png")}
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell align="center">{row?.fullName ? (row?.fullName) : (row?.firstName + " " + row?.lastName) || "-"}</TableCell>
                        <TableCell align="center">{row?.countryCode
                          ? (row?.countryCode.includes("+") ? "" : "+") +
                          row?.countryCode
                          : null}{" "}
                          {row?.phone || "-"}</TableCell>
                        <TableCell align="center">{row?.email || "-"}</TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateUserStatus(row?._id, !row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            <Tooltip title="View">
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-customers/userdetails/${row?._id}`)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            {hidePermission?.isEdit || UserData?.role === 1 ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/manage-customers/usersform/edit/${row?._id}`)
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No User Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={manageUser}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <ExportModal1
        open={open1}
        setOpen={setOpen1} />
      <WarnModal
        name="customer"
        setOpen={setOpen}
        open={open}
        handleDelete={() => handleDeleteUser(selectedId)}
      />
    </div>
  );
};

export default ManageUsers;
